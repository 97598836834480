<template>
  <div>
  <b-modal
    id="modal-update-issue-einvoice"
    centered
    hide-header
  >
    <b-card-header class="banned-background-color-primary">
      <div class="d-flex justify-content-between align-items-center block-header">
        <div class="d-flex justify-content-start align-items-center">
          <p class="mb-0 title-modal color-primary mr-1">
            {{ $t('golf_booking_detail_publish_einvoice') }}
          </p>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          class="cursor-pointer color-primary"
          @click="hideModal"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-50">
        <b-col>
          <div class="d-flex justify-content-start align-items-center">
          <p class="span-header-info">
            {{ $t('golf_booking_detail_info_publish_einvoice') }}
          </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <b-form-checkbox v-model="isCheckVisitorNoInvoice" @change="changeCheckVisitorNoInvoice(isCheckVisitorNoInvoice)">
            </b-form-checkbox>
           </div>
           <div class="form-group-einvoice">
                    <span class="span-text-einvoice">
              {{ $t('golf_sidebar_merge_visitor_no_invoice') }}
            </span>
                    </div>
          </b-col>
      </b-row>
      <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{ $t('golf_booking_detail_guest_take_einvoice') }}
            </span>
          </div>
              <div class="form-group-einvoice">
                    <b-form-input
                    v-model="BookingDetail.GuestNameTakeInvoice"
                    :placeholder="$t('golf_booking_detail_guest_take_einvoice')"
                        />
                    </div>

                  </b-col>
          </b-row>
          <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{  $t('golf_common_client') }}
            </span>
          </div>
              <div class="form-group-einvoice">
                        <v-select
                            v-model="BookingDetail.ClientDefaultId"
                            :options="companies"
                            label="ClientName"
                            :reduce="option => option.Id"
                            :disabled="isCheckVisitorNoInvoice"
                            :placeholder="$t('golf_common_client')"
                            @input="onChangeClient()"
                          />
                    </div>

                  </b-col>
          </b-row>
          <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{ $t('golf_tax_invoice_company_name') }}
            </span>
          </div>
              <div class="form-group-einvoice">
                        <b-form-input
                         v-model="BookingDetail.CompanyName"
                         :disabled="isCheckVisitorNoInvoice"
                         :placeholder="$t('golf_tax_invoice_company_name')"
                        />
                    </div>

                  </b-col>
          </b-row>
          <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{ $t('golf_booking_detail_guest_address') }}
            </span>
          </div>

              <div class="form-group-einvoice">
                        <b-form-input
                        v-model="BookingDetail.CompanyAddress"
                        :disabled="isCheckVisitorNoInvoice"
                          :placeholder="$t('golf_booking_detail_guest_address')"
                        />
                    </div>

                  </b-col>
          </b-row>
          <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{ $t('golf_tax_invoice_tax_code') }}
            </span>
          </div>
              <div class="form-group-einvoice">
                        <b-form-input
                        v-model="BookingDetail.TaxCode"
                        :disabled="isCheckVisitorNoInvoice"
                        :placeholder="$t('golf_tax_invoice_tax_code')"
                        />
                    </div>

                  </b-col>
          </b-row>
          <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{  $t('golf_common_phone')  }}
            </span>
          </div>
              <div class="form-group-einvoice">
                        <b-form-input
                        v-model="BookingDetail.GuestPhoneTakeInvoice"
                        :disabled="isCheckVisitorNoInvoice"
                        :placeholder="$t('golf_common_phone')"
                        />
                    </div>

                  </b-col>
          </b-row>
          <b-row class="mb-50">
        <b-col cols="12" md="12" class="d-flex" >
          <div class="span-group-einvoice d-flex align-content-center" >
            <span class="span-text-einvoice">
              {{$t('golf_tax_invoice_company_email') }}
            </span>
          </div>
              <div class="form-group-einvoice">
                        <b-form-input
                        v-model="BookingDetail.GuestEmailTakeInvoice"
                        :disabled="isCheckVisitorNoInvoice"
                          :placeholder="$t('golf_tax_invoice_company_email')"
                        />
                    </div>

                  </b-col>
          </b-row>
    </b-card-body>
    <template #modal-footer>
      <div class="">
        <b-button
          size="md"
          class="float-right ml-1"
          @click="hideModal"
        >
        {{ $t('golf_common_back') }}
        </b-button>
        <b-button
          variant="primary"
          size="md"
          class="float-right"
          @click="UpdateInfoGuest()"
          :disabled="disableButton"
        >
          {{ $t('golf_payment_publish_einvoice') }}
          <b-spinner
          v-if="disableButton"
          small
          label="Loading..."
        />
        </b-button>
      </div>
    </template>
  </b-modal>
</div>
</template>
<script>
import { booking } from '@/api/booking'
import { members } from '@/api/members'

export default {
  props: [
      'data',
  ],
  data() {
      return {
        fullNameDefaultVisitorNoInvoice: null,
        PaymentId: null,
        isCheckVisitorNoInvoice:false,
        BookingDetailDefaul:{},
        BookingDetail: {
          Id: null,
          GuestNameTakeInvoice: null,
          IsGuestTakeInvoice: true,
          CompanyName: '',
          CompanyAddress: '',
          TaxCode: '',
          GuestEmailTakeInvoice: '',
          GuestPhoneTakeInvoice: '',
          ClientDefaultId: null
        },
        disableButton: false,
        companies:[]
      
      }
  },
  created() {
    this.getConfigInfo_RCOM01()
},
  watch: {
      data(value) {
          // console.log(value)
      }
  },
  methods: {
   async open(item) {
      this.BookingDetailDefaul=item
      this.isCheckVisitorNoInvoice=false
      this.BookingDetail.Id = item.BookingDetailId
      this.BookingDetail.GuestNameTakeInvoice = item.GuestNameTakeInvoice !== null ? item.GuestNameTakeInvoice : item.FullName;
      this.BookingDetail.IsGuestTakeInvoice = true
      this.BookingDetail.CompanyName = item.CompanyName
      this.BookingDetail.CompanyAddress = item.CompanyAddress
      this.BookingDetail.TaxCode = item.TaxCode
      this.BookingDetail.GuestEmailTakeInvoice = item.GuestEmailTakeInvoice
      this.BookingDetail.GuestPhoneTakeInvoice = item.GuestPhoneTakeInvoice
      this.PaymentId = item.Id
      if( this.BookingDetail.CompanyName == null || this.BookingDetail.CompanyName === ""){
        if(item.ClientId != null){
          this.BookingDetail.CompanyName = this.companies.find(x => x.Id == item.ClientId).InvoiceName ? this.companies.find(x => x.Id ==item.ClientId).InvoiceName : ''
      this.BookingDetail.CompanyAddress = this.companies.find(x => x.Id == item.ClientId).Address1
      this.BookingDetail.GuestPhoneTakeInvoice = this.companies.find(x => x.Id == item.ClientId).Tel1
      this.BookingDetail.TaxCode = this.companies.find(x => x.Id == item.ClientId).TaxCode
      this.BookingDetail.GuestEmailTakeInvoice = this.companies.find(x => x.Id == item.ClientId).Email
        }
      }
      
      this.BookingDetail.GuestNameTakeInvoice = 
      (this.BookingDetail.GuestNameTakeInvoice != null && this.BookingDetail.GuestNameTakeInvoice.trim() !== "")  
      ? this.BookingDetail.GuestNameTakeInvoice : this.BookingDetail.FullName
      
      await this.getCompanies_RCL01(item.OpenDateTime)
      this.$bvModal.show('modal-update-issue-einvoice')
  },
  changeCheckVisitorNoInvoice(value) {
    if (value==false) {
      this.BookingDetail.IsGuestTakeInvoice = true
      this.BookingDetail.GuestNameTakeInvoice = this.BookingDetailDefaul.FullName;
      this.BookingDetail.CompanyName = this.BookingDetailDefaul.CompanyName
      this.BookingDetail.CompanyAddress = this.BookingDetailDefaul.CompanyAddress
      this.BookingDetail.TaxCode = this.BookingDetailDefaul.TaxCode
      this.BookingDetail.GuestEmailTakeInvoice = this.BookingDetailDefaul.GuestEmailTakeInvoice
      this.BookingDetail.GuestPhoneTakeInvoice = this.BookingDetailDefaul.GuestPhoneTakeInvoice
    } else {
      this.BookingDetail.IsGuestTakeInvoice = false
      this.BookingDetail.GuestNameTakeInvoice =this.fullNameDefaultVisitorNoInvoice
      this.BookingDetail.CompanyName = null
      this.BookingDetail.CompanyAddress = null
      this.BookingDetail.TaxCode = null
      this.BookingDetail.GuestEmailTakeInvoice = null
      this.BookingDetail.GuestPhoneTakeInvoice = null
      this.BookingDetail.ClientDefaultId = null
    }
      
  },
  onChangeClient() {
      if(this.BookingDetail.ClientDefaultId != null) {
        this.BookingDetail.CompanyName = this.companies.find(x => x.Id == this.BookingDetail.ClientDefaultId).InvoiceName ? this.companies.find(x => x.Id == this.BookingDetail.ClientDefaultId).InvoiceName : ''
        this.BookingDetail.CompanyAddress = this.companies.find(x => x.Id == this.BookingDetail.ClientDefaultId).Address1
        this.BookingDetail.GuestPhoneTakeInvoice = this.companies.find(x => x.Id == this.BookingDetail.ClientDefaultId).Tel1
        this.BookingDetail.TaxCode = this.companies.find(x => x.Id == this.BookingDetail.ClientDefaultId).TaxCode
        this.BookingDetail.GuestEmailTakeInvoice = this.companies.find(x => x.Id == this.BookingDetail.ClientDefaultId).Email
      }
      else{
        this.data.BookingDetail.CompanyName = ''
        this.data.BookingDetail.CompanyAddress = ''
        this.data.BookingDetail.TaxCode = ''
        this.data.BookingDetail.GuestEmailTakeInvoice = ''
        this.data.BookingDetail.GuestPhoneTakeInvoice = ''
      }
    },
      hideModal() {
          this.$bvModal.hide('modal-update-issue-einvoice')
      },
      async UpdateInfoGuest() {
    const body = {
      BookingDetail: {
          Id: this.BookingDetail.Id,
          GuestNameTakeInvoice: this.BookingDetail.GuestNameTakeInvoice,
          IsGuestTakeInvoice: this.BookingDetail.IsGuestTakeInvoice,
          CompanyName: this.BookingDetail.CompanyName,
          CompanyAddress: this.BookingDetail.CompanyAddress,
          TaxCode: this.BookingDetail.TaxCode,
          GuestEmailTakeInvoice: this.BookingDetail.GuestEmailTakeInvoice,
          GuestPhoneTakeInvoice: this.BookingDetail.GuestPhoneTakeInvoice,
        },
      }
      this.disableButton = true
      const response = await booking.updateInfoEinvoiceBookingDetail(body)
      this.disableButton = false
      this.showResToast(response)
      if (response.Status === '200') {
        await this.UpdateEInvoice()
    }
    },
    async UpdateEInvoice() {
      const body = {
      PaymentId: this.PaymentId,
      Type: 'PUBLISH',
    }
    this.disableButton = true
    const response = await booking.api_IPM07_PUBLISH_VIEW_EINVOICE_COURSE(body)
    this.disableButton = false
    this.showResToast(response)
    if (response.Status === '200') {
      this.$nextTick(() => {
          this.$bvModal.hide('modal-update-issue-einvoice')
          this.$emit('event', { type: 'after-publish-einvoice' })
        })
    }
    },

      async getConfigInfo_RCOM01() {
    const responseModule = await booking.getConfigInfo({ KeyGroup: 'MODULE' })
    if (responseModule.Status === '200') {
      this.fullNameDefaultVisitorNoInvoice = responseModule.Data.find(x => x.KeyCode === 'EINVOICE_BUYER_NAME_WHEN_NOT_GET_INVOICE').KeyValue
    }
  },
  async getCompanies_RCL01(OpenDateTime) {
    const response = await members.getCompanies({ OpenDateTime })
    if (response) {
      this.companies = response.Data.Client
    }
  },
      // async updateCaddyBook_RBK16() {
      //     let checkSelectCaddy = true
      //     for (const x of this.data) {
      //         if(x.BookingDetail.BookingDetailCaddy.filter(x => x.checked === true).length != x.BookingDetailService.length) {
      //             checkSelectCaddy = false
      //             break
      //         }
      //     }
      //     if(!checkSelectCaddy) {
      //         this.showToast('error', this.$t('golf_check_out_please_select_caddy_no_assignee_service'))
      //         return
      //     }
      //     for (let i = 0; i < this.data.length;i++) {
      //         const listCaddyDetail = this.data[i].BookingDetail.BookingDetailCaddy.filter(x => x.checked === true)
      //         listCaddyDetail.forEach(async (caddy,index) => {
      //             const body = {
      //                 CaddyId: caddy.CaddyId,
      //                 BookingDetailServiceId: this.data[i].BookingDetailService[index].Id
      //             }
      //             await booking.api_UBK16(body)
      //             if(i === this.data.length - 1 && index === listCaddyDetail.length - 1) {
      //                 this.$emit('event', {type: 'after-update-caddy'})
      //                 this.showToast('success', this.$t('golf_common_success'))
      //                 this.hideModal()
      //             }
      //         })
      //     }
      // },
      // checkSelectCaddyBalanceService(indexBK,indexCaddy) {
      //     let conut = 0
      //     this.data[indexBK].BookingDetail.BookingDetailCaddy.forEach((x,index) => {
      //         if(x.checked) {
      //             conut++
      //         }
      //     })
      //     if(this.data[indexBK].BookingDetailService.length < conut) {
      //         this.data[indexBK].BookingDetail.BookingDetailCaddy[indexCaddy].checked = false
      //         this.showToast('error', this.$t('golf_check_out_no_select_caddy_more_service'))
      //     }
      // }
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/variables/variables';
@import '@core/scss/vue/libs/vue-slider.scss';
:v-deep{
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 900px;
  }
  .b-table-sticky-header {
    overflow-y: auto !important;
    max-height: 85% !important;
  }
}


.banned-background-color-primary {
background: rgba(17, 74, 159, 0.12);
}
.title-modal {
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #114A9F;
line-height: 21px;
}
.content-body-modal {
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
}
.color-primary {
color: $primary ;
}
.form-group-einvoice{
width: 400px;
}
.span-header-info{
color: $primary !important;
font-size: 16px !important;
font-weight: bold !important;
}
.span-group-einvoice{
margin-right: 20px;
width: 100px;
.span-text-einvoice{
  margin-top: 9px !important;
}
}
.disabled {
background: rgba(193, 193, 193, 0.12)
}
</style>
