import { commonServices } from '@/api/common-services'
import { members } from '@/api/members'
import { booking } from '@/api/booking'
import UserAvatar from '@/views/modules/booking/UserAvatar.vue'
import PaymentForMember from '@/views/modules/ultils/PaymentForMember/PaymentForMember.vue'
import DeletePaymentDetail from '@/views/modules/ultils/PaymentForMember/DeletePaymentDetail'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import History from './History.vue'
import { apiBookingRetail } from '@/api/booking-retail'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F,
} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'
import EditServiceRetail from './EditServiceRetail.vue'

const BookingType = {
  RETAIL: 'RETAIL',
}

const BAR_LOCATION_4_MAIN_LOBBY_RT = 'BAR_LOCATION_4_MAIN_LOBBY_RT'

export default {
  name: 'AddGolfServiceRetail',
  components: {
    ValidationProvider,
    ValidationObserver,
    DeleteDialog,
    ConfirmDialog,
    UserAvatar,
    EditServiceRetail,
    PaymentForMember,
    DeletePaymentDetail,
    ShortKey,
    History,
    ConfirmDialog
  },
  props: {
    area: {
      type: String,
      default: 'BOOKING_RETAIL_CONFIG',
      validator(value) {
        return ['BOOKING_RETAIL_CONFIG'].includes(value)
      },
    },
    changeMember: {
      type: Boolean,
      default: false,
    },
    bookingRetailData: {
      type: Object,
      validator(value) {
        return value ? 'Id' in value : true
      },
      default() {
        return {
          Id: null,
        }
      },
    },
    memberDetail: {
      type: Object,
      default() {
        return {
          FullName: '',
          FirstName: '',
          LastName: '',
          Email: '',
          Id: '',
          PhoneNumber: '',
          Avatar: '',
          BDCOfBookingDetailId: null,
          BDC: null,
        }
      },
    },
    listServiceConfigAnual: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isChangeLocation: false,
      isOpen: false,
      listService: [],
      zone: [],
      hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      keyWord: null,
      productName: 'Chọn dịch vụ',
      activePosition: {
        ProductMenuId: null,
        ProductId: null,
      },
      isLoading: false,
      selectedServices: [],
      service: {},
      allServices: [],
      bookingRetail: {
        Id: null,
        BDC: '',
        BagtagCode: '',
        GolfClassId: null,
        MemberCardId: null,
        MemberId: null,
        BookingRetailDate: null,
      },
      // prop define
      paymentRetailProps: {
        Id: null,
      },
      deletePaymentRetail: null,
      addMember: {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
      },
      isRemove: false,
      listMember: [],
      listSex: [],
      listSexIcon: [
        {
          value: 'SEX_M',
          icon: 'ezGolf-icon-male',
        },
        {
          value: 'SEX_F',
          icon: 'ezGolf-icon-female',
        },
        {
          value: 'SEX_LGBT',
          icon: 'ezGolf-icon-other-gender',
        },
      ],
      currentLang: this.$i18n.locale,
      disableAddNew: false,
      PaymentRetaiId: null,
      listBarLocation: JSON.parse(localStorage.getItem('userGolfData')).BarLocation,
      currentLocationIdex: null,
      detailZoneId: null,
      isLoadingDetail: false,
      discount: {
        list: [],
      },
      optionDiscountType: [
        {
          value: 'FIX',
          label: this.$t('golf_common_money'),
        },
        {
          value: 'PERCENT',
          label: '%',
        },
      ],
      isOpen: false,
      allChildComponentClosed: [
        {
          ComponentId: 'modal-payment',
          IsActive: false,
        },
      ],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, F],
      ServiceCodeScan: null,
      pLang: null,
      pToken: null,
      pTimeZone: null,
      pTimeZoneOffset: null,
      pUrl: null,
      fileName: null,
      dataBookingRetailStatus: null,
      dataBookingRetailPaymentStatus: null,
      isChange: false
    }
  },
  created() {
    commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
        if (res) {
          this.listSex = res.Data.filter(x => x.LangId == this.currentLang)
        }
      })
    this.getParam()
    this.getListReasonDiscount_RRC01()
  },
  computed: {
    fieldsService() {
      return [
        { key: 'ServiceName', label: this.$t('golf_common_service_name') },
        { key: 'UnitPrice', label: this.$t('golf_common_price'), class: 'text-right' },
        { key: 'Quantity', label: this.$t('golf_common_quantity'), class: 'text-right' },
        { key: 'TotalAmount', label: this.$t('golf_common_total'), class: 'text-right' },
        { key: 'action', label: '', class: 'text-right' },
      ]
    },
    memberAvata() {
      return {
        Avatar: 'Avatar' in this.memberDetail ? this.memberDetail.Avatar : '',
      }
    },
    totalAmountServiceSelected() {
      if (Array.isArray(this.selectedServices) && this.selectedServices.length > 0) {
        return this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
      }
      return 0
    },
  },
  watch: {
    value(data) {
      if (data) {
        this.getServiceNameById(data)
      }
    },
    // addMember: {
    //   handler(val) {
    //     clearTimeout(this.timeout)
    //     this.timeout = setTimeout(() => {
    //       this.getListMember_RME01(val)
    //     }, 500)
    //   },
    //   deep: true,
    // },
    isRemove(val) {
      if (!val) {
        this.listMember = []
      }
    },
    currentLocationIdex() {
      // Reset khi đổi khu
      // this.selectedServices = []
      setTimeout(() => {
        this.isChangeLocation = false
      }, 100)

      this.getListService()
    },
    async isOpen(val) {
      if (val) {
        if (!this.memberDetail.Id && this.changeMember === true) {
          this.isRemove = true
        }
        setTimeout(() => {
          this.$refs.ScanCode.$refs.search.focus()
        }, 200)
      } else {
        this.isChange = false
        // if(this.dataBookingRetailStatus != 'CHECK_OUT' && this.dataBookingRetailPaymentStatus == 'FULL_PAY' && this.bookingRetail.Id) {
        //   await this.checkOutBookingRetail()
        // }
        this.PaymentRetaiId = null
        this.$emit('event', { type: 'close_sidebar_service_retail' })
      }
      this.$emit('is-open', { ComponentId: 'add-service-item-retail', IsActive: val })
    },
  },
  methods: {
    async checkOutBookingRetail() {
      const body = {
        BookingRetailId: this.bookingRetail.Id
      }
      const res =  await apiBookingRetail.checkOutRetail_ICIR02(body)
      this.showResToast(res)
      this.getDataBookingRetail(this.bookingRetail.Id, true)

    },
    async getListReasonDiscount_RRC01() {
      const response = await booking.getListReasonDiscount({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      })
      if (response.Status === '200') {
        this.discount.list = response?.Data?.Reason
      }
    },
    handleChangeDiscountAmountAndType() {
      this.isChange = true
      this.selectedServices = this.selectedServices.map(item => {
        // Nếu tồn tại lý do giảm giá và giá trị giảm giá
        if (item.BookingRetailServiceDiscount[0].ReasonCode && item.BookingRetailServiceDiscount[0].Amount && item.BookingRetailServiceDiscount[0].DiscountType) {
          let tmp = 0
          if (item.BookingRetailServiceDiscount[0].DiscountType == 'PERCENT') {
            tmp = ((item.UnitPrice * parseFloat(item.Quantity)) * parseFloat(item.BookingRetailServiceDiscount[0].Amount)) / 100
          } else {
            tmp = parseFloat(item.BookingRetailServiceDiscount[0].Amount)
          }
          item.TotalAmount = (item.UnitPrice * parseFloat(item.Quantity)) - tmp
        } else {
          // nếu không có giảm giá
          item.TotalAmount = item.UnitPrice * parseFloat(item.Quantity)
          item.Discount[0].DiscountAmount=0
        }

        return item
      })
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    tabActivated(newTabIndex, oldTabIndex, event) {
      // Chỉ hiển thị thông báo lưu đơn hàng khi có sản phẩm trong giỏ hàng
      if (this.selectedServices.length) {
        // Chỉ đổi giá trị khi đã confirm, tức this.isChangeLocation dc set = true, isChangeLocation được set = true khi confirm và sẽ được set = false ngay sau khi update xong thông qua watch currentLocationIdex
        if (!this.isChangeLocation) {
          this.$refs.resetServiceList.open({
            dataConfirm: {
              title: this.$t('golf_common_change_location_bar'),
              content: this.$t('golf_common_list_service_will_be_reset'),
              hideFooter: false,
              newTabIndex,
              oldTabIndex,
            // event,
            },
          })
          event.preventDefault()
        }
      }
    },
    confirmDialogReset(data) {
      this.selectedServices = []
      this.isChangeLocation = true
      this.currentLocationIdex = data.dataConfirm.newTabIndex
    },
    openForm() {
      this.disableAddNew = false
      this.detailZoneId = null
      this.dataBookingRetailStatus = null
      this.dataBookingRetailPaymentStatus = null
      this.dataBookingRetail = null
      // this.getListZone(this.area)
      this.bookingRetail = {
        Id: this.bookingRetailData.Id,
        BDC: '',
        BagtagCode: '',
        GolfClassId: null,
        MemberCardId: null,
        MemberId: null,
        BookingRetailDate: null,
      }
      this.paymentRetailProps = {
        Id: null,
      }
      this.selectedServices = []
      if (this.bookingRetail.Id) {
        this.getDataBookingRetail(this.bookingRetail.Id, true)
      }
    },
    async getListMember_RME01() {
      if (this.addMember.FullName || this.addMember.CardCode || this.addMember.Email || this.addMember.PhoneNumber) {
        const requestData = {
          MemberName: (this.addMember.FullName ? this.addMember.FullName : ''),
          CardCode: this.addMember.CardCode,
          CardTypeId: null,
          MemberType: null,
          Email: this.addMember.Email,
          PhoneNumber: this.addMember.PhoneNumber,
        }

        const response = await members.getListMember(requestData)
        if (response.Status === '200') {
          this.listMember = response?.Data?.Member
        }
      } else {
        this.listMember = []
      }
    },
    addNewMemberToDetailBooking_IME01() {
      const body = {
        Member: {
          FullName: this.addMember.FullName,
          FirstName: this.addMember.FirstName,
          LastName: this.addMember.LastName,
          CardCode: this.addMember.CardCode,
          SexCode: this.addMember.SexCode,
          PhoneNumber: this.addMember.PhoneNumber,
          Email: this.addMember.Email,
        },
      }
      members.createMember(body).then(res => {
        this.showResToast(res)
        if (res.Status == '200') {
          this.listMember = []
          this.isRemove = false
          this.memberDetail = {
            Id: res.Data.MemberId,
            FullName: this.addMember.FullName,
            FirstName: this.addMember.FirstName,
            LastName: this.addMember.LastName,
            CardCode: this.addMember.CardCode,
            SexCode: this.addMember.SexCode,
            PhoneNumber: this.addMember.PhoneNumber,
            Email: this.addMember.Email,
          }

          this.addMember = {
            FullName: null,
            FirstName: null,
            LastName: null,
            PhoneNumber: null,
            Email: null,
            SexCode: 'SEX_M',
            CardCode: null,
          }
        }
      })
    },
    
    addMemberToDetailBooking(item) {
      this.memberDetail = {
        FullName: item.MemberName,
        FirstName: item.MemberName,
        LastName: item.MemberName,
        Email: item.Email,
        Id: item.Id,
        PhoneNumber: item.PhoneNumber,
        Avatar: item.Avatar,
      }
      this.addMember = {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
      }
      this.isRemove = false
    },
    cancelAddMember() {
      this.listMember = []
      this.isRemove = false
      this.addMember = {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
      }
    },
    // Form Event
    onClickSaveProvisionalInvoice() {
      this.$refs.servicesForm.validate().then(success => {
        if (success) {
          this.disableAddNew = true
          if (!this.memberDetail.Id) {
            this.showToast('error', this.$t('golf_payment_retail_select_member'))
            this.disableAddNew = false
            return
          }

          const isAddService = this.bookingRetail.Id === null
          const bodyDataPost = {
            BookingRetail: {
              BDC: this.bookingRetail.BDC,
              MemberId: this.memberDetail.Id,
              MemberCardId: this.bookingRetail.MemberCardId,
              ZoneId: this.listBarLocation[this.currentLocationIdex].Id,
              GolfClassId: this.bookingRetail.GolfClassId,
              BagtagCode: this.bookingRetail.BagtagCode,
              Note: this.memberDetail.Note,
              BookingRetailService: this.getParamsServiceRetail(isAddService),
              BDCOfBookingDetailId: this.memberDetail.BDCOfBookingDetailId,
            },
          }
          let bookingRetailId = this.bookingRetail.Id
          if (isAddService) {
            members.addPackageRetail(bodyDataPost).then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                bookingRetailId = res?.Data?.BookingRetail?.Id
                this.getDataBookingRetail(bookingRetailId)
                // this.$emit('event', { type: 'after_upate_booking_retail' })
              }
            })
          } else {
            bodyDataPost.BookingRetail.Id = bookingRetailId
            bodyDataPost.BookingRetail.BookingRetailDate = this.bookingRetail.BookingRetailDate
            members.updatePackageRetail(bodyDataPost).then(res => {
              this.showResToast(res)
              this.getDataBookingRetail(bookingRetailId)
            })
          }
          this.isChange = false
          setTimeout(() => {
            this.disableAddNew = false
          }, 1000)
        }
      })
    },
    async getDataBookingRetail(bookingRetailId, updateInvoice) {
      this.isLoadingDetail = true
      const dataPost = {
        BookingRetail: {
          Id: bookingRetailId,
        },
      }
      await booking.getBookingRetail(dataPost).then(res => {
        if (res.Status === '200') {
          this.bookingRetail.Id = res?.Data?.BookingRetail.Id
          this.PaymentRetaiId = res?.Data?.BookingRetail.PaymentId
          this.dataBookingRetailStatus = res?.Data?.BookingRetail.BookingRetailStatus
          this.dataBookingRetailPaymentStatus = res?.Data?.BookingRetail.PaymentRetailStatus
          const bookingRetailResponse = res?.Data?.BookingRetail
          this.memberDetail.BDCOfBookingDetailId = res?.Data?.BookingRetail.BdcofBookingDetailId
          this.memberDetail.BDC = res?.Data?.BookingRetail.BDC
          this.memberDetail.Note = res?.Data?.BookingRetail.Note
          this.detailZoneId = res?.Data?.BookingRetail.ZoneId

          this.currentLocationIdex = this.listBarLocation.findIndex(x => x.Id == this.detailZoneId)
          // Delay để lấy danh sách dịch vụ theo khu vực rồi mới hiện item đã mua
          setTimeout(() => {
            this.updateBookingRetail(bookingRetailResponse, updateInvoice)
          }, 1)
          this.$forceUpdate()
        } else {
          this.showResToast(res)
        }
      })

      this.isLoadingDetail = false
    },
    updateBookingRetail(newBookingRetail, updateInvoice) {
      this.bookingRetail.Amount = null
      this.bookingRetail.TotalAmount = null
      Object.keys(this.bookingRetail).forEach(brKey => {
        if (brKey in newBookingRetail) {
          this.bookingRetail[brKey] = newBookingRetail[brKey]
        }
      })
      const addtionalServices = newBookingRetail.AddtionalService || []
      const newServiceSelected = this.getDetailServiceAdditional(addtionalServices)

      this.selectedServices = [...newServiceSelected]
      if(!updateInvoice) {
        this.onClickPayment(false)
      }
    },
    getDetailServiceAdditional(addtionalServices) {
      let services = []
      addtionalServices.forEach(addtionalService => {
        const bookingRetailServices = addtionalService.BookingRetailService || []
        services = [...services, ...bookingRetailServices]
      })
      return services
    },
    async onClickPayment(isCheckOpenPopup) {
      if(this.isChange) {
        this.showToast('error', this.$t('golf_payment_please_save_order'))
        return
      }
      if (this.bookingRetail.Id === null) {
        this.showToast('error', this.$t('golf_payment_no_order_created_yet'))
        return
      }
      // if (this.selectedServices.length === 0) {
      //   this.showToast('error', this.$t('golf_payment_service_has_not_been_selected'))
      //   return
      // }
      // if (this.totalAmountServiceSelected === 0) {
      //   this.showToast('error', this.$t('golf_payment_total_service_charge_invalid'))
      //   return
      // }
      const dataPost = {
        PaymentRetail: {
          MemberId: this.memberDetail.Id,
          BookingRetailId: this.bookingRetail.Id,
          ZoneId: this.listBarLocation[this.currentLocationIdex].Id,
          Status: 'TEMPORARY',
          PaymentRetailBooking: [{
            BookingRetailId: this.bookingRetail.Id,
            PaymentRetailBookingService: this.getParamPaymentRetailBookingService(),
          }],
          PaymentRetailDiscount: [],
        },
      }
      await members.createPaymentRetail(dataPost).then(res => {
        if (res.Status === '200') {
          const bookingRetailIdResponse = res?.Data?.PaymentRetail
          this.paymentRetailProps = {
            ...bookingRetailIdResponse,
          }
          if(isCheckOpenPopup) {
            this.$root.$emit('bv::show::modal', 'modal-payment-retail')
          } else {
            this.getDataBookingRetail(this.bookingRetail.Id,true)
          }
        } else {
          this.showResToast(res)
        }
      })
    },
    getParamsServiceRetail(isAddService) {
      const servicesRetail = []
      const assignDiscountId = !isAddService
      this.selectedServices.forEach(item => {
        const bookingRetaiServiceDiscount = item.BookingRetaiServiceDiscount || []
        const itemService = {
          ServiceId: item.ServiceId,
          UnitPrice: parseFloat(item.UnitPrice || 0),
          Quantity: parseFloat(item.Quantity || 0),
          Amount: item.TotalAmount || 0,
          TotalAmount: item.TotalAmount || 0,
          ServiceType: item.ServiceType,
          MemberSubscriptionId: item.MemberSubscriptionId || null,
          MemberSubscriptionFromDate: item.MemberSubscriptionFromDate || null,
          MemberSubscriptionToDate: item.MemberSubscriptionToDate || null,
          BookingRetailServiceDiscount: item.BookingRetailServiceDiscount, // this.getParamRetailServiceDiscount(bookingRetaiServiceDiscount, assignDiscountId),

        }
        if (!isAddService && 'Id' in item && item.Id) {
          itemService.Id = item.Id
        }
        servicesRetail.push(itemService)
      })
      return servicesRetail
    },
    getParamRetailServiceDiscount(bookingsRetaiServiceDiscount, assignDiscountId) {
      const newbookingRetaiServiceDiscount = []
      bookingsRetaiServiceDiscount.forEach(bookingRetaiServiceDiscount => {
        const newItemRetaiServiceDiscount = {
          DiscountCode: bookingRetaiServiceDiscount.DiscountCode,
          DiscountAmount: bookingRetaiServiceDiscount.DiscountAmount,
          DiscountType: bookingRetaiServiceDiscount.DiscountType,
          Reasons: bookingRetaiServiceDiscount.Reasons,
          Amount: bookingRetaiServiceDiscount.Amount,
        }
        if (assignDiscountId && bookingRetaiServiceDiscount.Id) {
          newItemRetaiServiceDiscount.Id = bookingRetaiServiceDiscount.Id
        }
        newbookingRetaiServiceDiscount.add(newItemRetaiServiceDiscount)
      })
      return newbookingRetaiServiceDiscount
    },
    getParamPaymentRetailBookingService() {
      const servicesRetail = []
      this.selectedServices.forEach(item => {
        const bookingRetaiServiceDiscount = item.BookingRetaiServiceDiscount || []
        const itemService = {
          BookingRetailServiceId: item.Id,
          ServiceId: item.ServiceId,
          UnitPrice: parseFloat(item.UnitPrice || 0),
          Amount: item.Amount,
          TotalAmount: item.TotalAmount,
          Quantity: parseFloat(item.Quantity || 0),
          RateId: null,
          ServiceType: item.ServiceType,
          MemberSubscriptionId: item.MemberSubscriptionId || null,
          MemberSubscriptionFromDate: item.MemberSubscriptionFromDate || null,
          MemberSubscriptionToDate: item.MemberSubscriptionToDate || null,
          PaymentRetailBookingServiceDiscount: this.getParamPaymentRetailBookingServiceDiscount(bookingRetaiServiceDiscount),
        }
        servicesRetail.push(itemService)
      })
      return servicesRetail
    },
    getParamPaymentRetailBookingServiceDiscount(bookingsRetaiServiceDiscount) {
      const newbookingRetaiServiceDiscount = []
      bookingsRetaiServiceDiscount.forEach(bookingRetaiServiceDiscount => {
        const newItemRetaiServiceDiscount = {
          DiscountCode: bookingRetaiServiceDiscount.DiscountCode,
          DiscountType: bookingRetaiServiceDiscount.DiscountType,
          Reasons: bookingRetaiServiceDiscount.Reasons,
          Amount: bookingRetaiServiceDiscount.Amount,
        }
        newbookingRetaiServiceDiscount.add(newItemRetaiServiceDiscount)
      })
      return newbookingRetaiServiceDiscount
    },
    hideSideBar() {
      if(this.dataBookingRetailStatus != 'CHECK_OUT' && this.dataBookingRetailPaymentStatus == 'FULL_PAY' && this.bookingRetail.Id) {
        this.$refs.confirmDialogCheckOut.open({
          Id: this.bookingRetail.Id,
          dataConfirm: {
            title: 'Check out',
            content: 'Booking đã thanh toán đủ tiền. Bạn có muốn check out trước khi đóng không?',
          }
        })
        return
      }
      this.$emit('event', { type: 'close_sidebar_service_retail' })
      this.$root.$emit('bv::toggle::collapse', 'add-service-item-retail')
    },
    removeItem(serviceId) {
      this.isChange = true
      this.selectedServices = this.selectedServices.filter(item => item.ServiceId !== serviceId)
    },
    handleSearch() {
      // const search = new RegExp(this.keyWord, 'i')
      const tmpAllService = []
      this.allServices.forEach(x => {
        const menuDetail = []
        x.MenuDetail.forEach(y => {
          if (y.ProductNameUpperCase.includes(this.keyWord.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
          if (y.CodeUpperCase && y.CodeUpperCase.includes(this.keyWord.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
        })

        if (menuDetail.length) {
          tmpAllService.push({
            ProductMenuId: x.ProductMenuId,
            MenuCode: x.MenuCode,
            MenuName: x.MenuName,
            IsChildOfBar: x.IsChildOfBar,
            ParentMenuId: x.ParentMenuId,
            IsActive: x.IsActive,
            HotelId: x.HotelId,
            MenuDetail: menuDetail,
          })
        }
      })

      this.listService = tmpAllService
    },
    scanServiceCode() {
      const tmpAllService = []
      this.allServices.forEach(x => {
        const menuDetail = []
        x.MenuDetail.forEach(y => {
          if (y.ProductNameUpperCase.includes(this.ServiceCodeScan.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
          if (y.CodeUpperCase && y.CodeUpperCase.includes(this.ServiceCodeScan.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
        })

        if (menuDetail.length) {
          tmpAllService.push({
            ProductMenuId: x.ProductMenuId,
            MenuCode: x.MenuCode,
            MenuName: x.MenuName,
            IsChildOfBar: x.IsChildOfBar,
            ParentMenuId: x.ParentMenuId,
            IsActive: x.IsActive,
            HotelId: x.HotelId,
            MenuDetail: menuDetail,
          })
        }
      })
      if (tmpAllService.length === 1 && tmpAllService[0].MenuDetail.length === 1) {
        this.selectSevice(tmpAllService[0].MenuDetail[0])
      } else {
        this.showToast('error', 'Không tìm thấy dịch vụ phù hợp')
      }
      this.ServiceCodeScan = null
      setTimeout(() => {
        this.$refs.ScanCode.$refs.search.focus()
      }, 50)
    },
    handleChangeQuantity(evt) {
      this.isChange = true
      if (evt.target.value.trim().length === 0 || parseFloat(evt.target.value) < 0) {
        evt.target.value = 0
      }
      this.selectedServices.forEach(itemService => {
        itemService.TotalAmount = (itemService.UnitPrice || 0) * parseFloat(itemService.Quantity || 0)
      })
    },
    // async getListZone(area) {
    //   this.zone = []
    //   const zoneOfRetail = []
    //   const bodyArea = {
    //     KeyGroup: area,
    //   }
    //   await commonServices
    //     .getCommon(bodyArea)
    //     .then(res => {
    //       if (res.Status === '200') {
    //         res.Data.forEach(x => {
    //           if (x.KeyCode === BAR_LOCATION_4_MAIN_LOBBY_RT) {
    //             zoneOfRetail.push(x.KeyValue)
    //           }
    //         })
    //         this.zone = [...zoneOfRetail]
    //         this.getListService()
    //       }
    //     })
    // },
    async getListService() {
      this.isLoading = true
      const body = {
        HotelId: this.hotelId,
        ZoneId: [this.listBarLocation[this.currentLocationIdex].Id.toString()],
        BookingType: BookingType.RETAIL,
      }
      await commonServices
        .getListService(body)
        .then(res => {
          if (res.Status === '200') {
            this.listService = res.Data.Menu
            this.allServices = res.Data.Menu
            if (res.Data.Menu) {
              this.allServices.forEach(x => {
                x.MenuDetail.forEach(y => {
                  y.ProductNameUpperCase = y.ProductName.split(' ').join('').toUpperCase()
                  if (y.Code) {
                    y.CodeUpperCase = y.Code.split(' ').join('').toUpperCase()
                  }
                })
              })
            }
            // Hiển thị tên service đã được chọn
            this.getServiceNameById(this.value)
          }
        }).finally(() => {
          this.isLoading = false
          this.$forceUpdate()
        })
    },
    selectSevice(selectSevice) {
      selectSevice.Quantity = 1
      if(this.listServiceConfigAnual.find(x => x == selectSevice.ProductId)) {
        selectSevice.IsAnnualFee = true
      } else {
        selectSevice.IsAnnualFee = false
      }
      // this.isOpen = false
      this.service = selectSevice
      this.$bvModal.show('edit-service-booking')
    },
    handleEvent(result) {
      if (result.type === 'add_service') {
        this.isChange = true
        const selectSevice = result.data
        this.value = selectSevice.ProductId
        const isExitsServiceSelected = this.selectedServices.some(item => item.ServiceId === selectSevice.ProductId)
        if (!isExitsServiceSelected) {
          const newServiceSelected = {
            Id: null,
            ServiceId: selectSevice.ProductId,
            ServiceName: selectSevice.ProductName || '',
            Quantity: selectSevice.Quantity || 1,
            UnitPrice: selectSevice.ProductPrice || 0,
            TotalAmount: parseFloat(selectSevice.ProductPrice * selectSevice.Quantity || 0),
            ServiceType: selectSevice.ProductType,
            MemberSubscriptionId: null,
            MemberSubscriptionFromDate: null,
            MemberSubscriptionToDate: null,
            BookingRetailServiceDiscount: [
              {
                DiscountCode: null,
                ReasonCode: null,
                Amount: 0,
                DiscountType: 'PERCENT',
                Reasons: null,
              },
            ],
          }
          this.selectedServices = [...this.selectedServices, ...[newServiceSelected]]
        } else {
          this.selectedServices.forEach(x => {
            if (x.ServiceId === selectSevice.ProductId) {
              x.Quantity = parseFloat(x.Quantity) + parseFloat(selectSevice.Quantity);
              x.TotalAmount = parseFloat(x.UnitPrice * x.Quantity)
            }
          })
        }
      }
      if (result.type === 'add_subscription') {
        this.isChange = true
        const selectSevice = result.data
        this.value = selectSevice.ProductId
        const isExitsServiceSelected = this.selectedServices.some(item => item.ServiceId === selectSevice.ProductId)
        if (!isExitsServiceSelected) {
          const newServiceSelected = {
            Id: null,
            ServiceId: selectSevice.ProductId,
            ServiceName: selectSevice.ProductName || '',
            Quantity: selectSevice.Quantity || 1,
            UnitPrice: selectSevice.ProductPrice || 0,
            TotalAmount: parseFloat(selectSevice.ProductPrice * selectSevice.Quantity || 0),
            ServiceType: selectSevice.ProductType,
            MemberSubscriptionId: selectSevice.MemberSubscriptionId,
            MemberSubscriptionFromDate: selectSevice.MemberSubscriptionFromDate,
            MemberSubscriptionToDate: selectSevice.MemberSubscriptionToDate,
            Discount: [
              {
                DiscountCode: null,
                ReasonCode: null,
                Amount: 0,
                DiscountType: 'PERCENT',
                Reasons: null,
              },
            ],
          }
          this.selectedServices = [...this.selectedServices, ...[newServiceSelected]]
        }
      }
    },
    async getServiceNameById(serviceId) {
      await this.listService.find(x => {
        x.MenuDetail.find(y => {
          if (y.ProductId === serviceId) {
            this.productName = y.ProductName
            this.activePosition.ProductMenuId = x.ProductMenuId
            this.activePosition.ProductId = y.ProductId
          }
        })
      })
    },
    closePayment() {},
    Event(result) {
      if (result.type) {
        if (result.type === 'open_modal_delete_payment_detail') {
          this.deletePaymentRetail = result.deletePaymentRetail
          this.$root.$emit('bv::show::modal', 'modal-delete-payment-detail')
        }
        if (result.type === 'after-delete-payment-detail') {
          this.$refs.PaymentForMember.getDetailPayment()
        }
        if (result.type === 'after-close-modal-payment') {
          this.getDataBookingRetail(this.bookingRetail.Id,true)
        }
      }
    },
    OpenInvoice() {
      const paymentRetailId = this.PaymentRetaiId
      const routeData = this.$router.resolve({ name: 'retailInvoice', params: { id: paymentRetailId } })
      window.open(routeData.href, '_blank')
    },
    onTriggeredEventHandler(payload) {
      if (payload.key === F1) {
        if (this.PaymentRetaiId) {
          this.getInoicePdf()
        }
      }
      if (payload.key === F2) {
        if(!this.disableAddNew) {
          this.onClickSaveProvisionalInvoice()
        }
      }
      if (payload.key === F3) {
        this.onClickPayment(true)
      }
      if (payload.evt.ctrlKey && payload.key === F) {
        this.$refs.ScanCode.$refs.search.focus()
      }
      // if (payload.key === F4) {
      //   this.hideSideBar()
      // }
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    async getParam() {
      const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
      this.pLang = localStorage.getItem('systemLanguage') || '1000000'
      this.pToken = (userGolfData) ? userGolfData.AccessToken : ''
      this.pTimeZone = localStorage.getItem('timeZone')
      this.pTimeZoneOffset = localStorage.getItem('timeZoneOffset')
      await this.api_RCOM01()
      await this.api_RCF00()
    },
    async api_RCF00() {
      await commonServices.api_RCF00().then(response => {
        this.pUrl = `${response.Data.find(x => x.SettingCode == 'SITE_API_URL').SettingValue}/api/course`
      })
    },
    async api_RCOM01() {
      const body = {
        KeyGroup: 'REPORT_CONFIG',
      }
      await commonServices.getCommon(body).then(response => {
        this.fileName = response.Data.find(x => x.KeyCode == 'IV03_REPORT_FILE_NAME').KeyValue
      })
    },
    async getInoicePdf() {
      const body = {
        ReportName: this.fileName,
        Params: {
          pIsGetAll: true, pInvoiceIndex: 0, pToken: this.pToken, pPaymentRetailId: this.PaymentRetaiId, pUrl: this.pUrl, pLang: this.pLang, pTimeZone: this.pTimeZone, pTimeZoneOffset: this.pTimeZoneOffset,
        },
      }
      await booking.api_RRPDF(body).then(response => {
        const decodedData = Buffer.from(response.Data.FileContents, 'base64')
        const newBlob = new Blob([decodedData], {
          type: 'application/pdf',
        })
        const iframe = document.createElement('iframe')
        const url = window.URL.createObjectURL(newBlob)
        iframe.src = url
        iframe.style.display = 'none'
        iframe.onload = () => {
          iframe.contentWindow.focus()
          iframe.contentWindow.print()
        }
        document.body.appendChild(iframe)
      })
    },
    CopyText(text) {
      document.body.insertAdjacentHTML('beforeend', `<div id="copy" contenteditable>${text}</div>`)
      document.getElementById('copy').focus()
      document.execCommand('selectAll')
      document.execCommand('copy')
      document.getElementById('copy').remove()
    },
    async UndoCheckOut() {
      const body = {
        BookingRetailId: [this.bookingRetail.Id],
      }
      await apiBookingRetail.api_ICIR03(body).then(res => {
        this.getDataBookingRetail(this.bookingRetail.Id)
      })
    },
    openModalHistoryRetail() {
      this.$refs.History.open()
    },
    cancelconfirmDialogCheckOut() {
      this.$emit('event', { type: 'close_sidebar_service_retail' })
      this.$root.$emit('bv::toggle::collapse', 'add-service-item-retail')
    }
  },
}
