<template>
  <div>
    <b-modal
      id="modal-update-booking-einvoice"
      v-model="dialog"
      centered
      hide-header
      @hidden="hideModalUpdateHole"
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <div class="d-flex justify-content-start align-items-center">
            <p class="mb-0 title-modal color-primary mr-1">
              {{ $t('golf_booking_detail_info_guest_take_einvoice') }}
            </p>
          </div>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModalUpdateHole"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{ $t('golf_booking_detail_guest_take_einvoice') }}
              </span>
            </div>
                <div class="form-group-einvoice">
                          <!-- <b-form-input
                            :placeholder="$t('golf_tax_invoice_company_name')"
                          />
                      </div> -->
                      <b-form-input
                      v-model="data.BookingDetail.GuestNameTakeInvoice"
                      :placeholder="$t('golf_booking_detail_guest_take_einvoice')"
                          />
                      </div>

                    </b-col>
            </b-row>
            <b-row class="mb-50">
              <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{ $t('golf_common_client') }}
              </span>
            </div>
                      <div class="form-group-einvoice">
                          <v-select
                              v-model="data.BookingDetail.ClientDefaultId"
                              :options="companies"
                              label="ClientName"
                              :reduce="option => option.Id"
                              :placeholder="$t('golf_common_client')"
                              @input="onChangeClient()"
                            />
                      </div>
                    </b-col>
                  </b-row>
            <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{ $t('golf_tax_invoice_company_name') }}
              </span>
            </div>
                <div class="form-group-einvoice">
                          <b-form-input
                           v-model="data.BookingDetail.CompanyName"
                           :placeholder="$t('golf_tax_invoice_company_name')"
                          />
                      </div>

                    </b-col>
            </b-row>
            <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{ $t('golf_booking_detail_guest_address') }}
              </span>
            </div>

                <div class="form-group-einvoice">
                          <b-form-input
                          v-model="data.BookingDetail.CompanyAddress"
                            :placeholder="$t('golf_booking_detail_guest_address')"
                          />
                      </div>

                    </b-col>
            </b-row>
            <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{ $t('golf_tax_invoice_tax_code') }}
              </span>
            </div>
                <div class="form-group-einvoice">
                          <b-form-input
                          v-model="data.BookingDetail.TaxCode"
                            :placeholder="$t('golf_tax_invoice_tax_code')"
                          />
                      </div>

                    </b-col>
            </b-row>
            <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{  $t('golf_common_phone')  }}
              </span>
            </div>
                <div class="form-group-einvoice">
                          <b-form-input
                          v-model="data.BookingDetail.GuestPhoneTakeInvoice"
                            :placeholder="$t('golf_common_phone')"
                          />
                      </div>

                    </b-col>
            </b-row>
            <b-row class="mb-50">
          <b-col cols="12" md="12" class="d-flex" >
            <div class="span-group-einvoice d-flex align-content-center" >
              <span class="span-text-einvoice">
                {{$t('golf_tax_invoice_company_email') }}
              </span>
            </div>
                <div class="form-group-einvoice">
                          <b-form-input
                          v-model="data.BookingDetail.GuestEmailTakeInvoice"
                            :placeholder="$t('golf_tax_invoice_company_email')"
                          />
                      </div>

                    </b-col>
            </b-row>
      </b-card-body>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            size="md"
            class="float-right ml-1"
            @click="hideModalUpdateHole"
          >
          {{ $t('golf_common_back') }}
          </b-button>
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            @click="save"
          >
            {{ $t('golf_common_confirm') }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="dialog"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import VueSlider from 'vue-slider-component'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
// import {
//   BTableSimple, BTable, BCardHeader, BFormGroup, BFormInput, BModal, BCardBody, BForm, BRow, BCol, BFormTextarea,
//   BThead, BTr, BTh, BTd, BTbody, BFormCheckbox
// } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { booking } from '@/api/booking'
import dayjs from 'dayjs'

export default {
  name: 'UpdateBookingEInvoice',
  components: {
    // BModal, BCardHeader, BFormInput, BFormGroup, BCardBody, BForm,  ValidationProvider, ValidationObserver,
    // BRow, BCol, BFormTextarea, BTable, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox,
    vSelect, VueSlider,ShortKey
  },
  props: [
    'data',
    'companies',
  ],
  data() {
    return {
      packages: {
        list: [],
      },
      selected: [],
      reason: {
        list: [],
        selected: null,
      },
      isIncludedTax: true,
      isFreeTax: true,
      dialog: false,
      allChildComponentClosed: [ ],
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S]
    }
  },
  created(){
     //this.map()
     //this.onChangeClient()
  },
  computed: {
    fieldstbl() {
      return [
        // { key: 'group', label: this.$t('golf_package_group') },
        // { key: 'PackageName', label: this.$t('golf_package_name') },
        // { key: 'PlayNumber', label: this.$t('golf_package_play_number') , class: 'text-right'},
        // { key: 'ExpirationDate', label: this.$t('golf_package_expiration_date'), class: 'text-right' },
        // { key: 'Player', label: this.$t('golf_package_player') },
        // { key: 'PackageInfo', label: this.$t('golf_package_packageInfo') },

      ]
    },
  },
  watch: {
    data(val) {
    },
    dialog(val) {
      this.$emit('is-open', {ComponentId: "modal-update-booking-einvoice",IsActive:val})
      if( this.data.BookingDetail.CompanyName == null || this.data.BookingDetail.CompanyName === ""){
        if(this.data.BookingDetail.ClientId != null){
      this.data.BookingDetail.CompanyName = this.companies.find(x => x.Id == this.data.BookingDetail.ClientId).InvoiceName ? this.companies.find(x => x.Id ==this.data.BookingDetail.ClientId).InvoiceName : ''
      this.data.BookingDetail.CompanyAddress = this.companies.find(x => x.Id == this.data.BookingDetail.ClientId).Address1
      this.data.BookingDetail.GuestPhoneTakeInvoice = this.companies.find(x => x.Id == this.data.BookingDetail.ClientId).Tel1
      this.data.BookingDetail.TaxCode = this.companies.find(x => x.Id == this.data.BookingDetail.ClientId).TaxCode
      this.data.BookingDetail.GuestEmailTakeInvoice = this.companies.find(x => x.Id == this.data.BookingDetail.ClientId).Email
        }
      }
      
      this.data.BookingDetail.GuestNameTakeInvoice = 
      (this.data.BookingDetail.GuestNameTakeInvoice != null && this.data.BookingDetail.GuestNameTakeInvoice.trim() !== "")  
      ? this.data.BookingDetail.GuestNameTakeInvoice : this.data.BookingDetail.FullName
      
    }
  },
  async mounted() {},
  methods: {
    open() {
      if (!this.checkPermission('UBK04')) {
        return
      }
      this.dialog = true

     
    },
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    hideModalUpdateHole() {
      this.dialog = false
      this.$nextTick(() => {
            this.$bvModal.hide('modal-update-booking-einvoice')
            this.$emit('event', { type: 'after-update-booking-einvoice' })
          })
    },
    map(){
      //this.data.BookingDetail.map(e=> ({...e,ClientDefaultId: e.ClientId }))
      if(this.data.BookingDetail != null) {
        this.data.BookingDetail.ClientDefaultId = this.data.BookingDetail.ClientId
      }
    },
    onChangeClient() {
      if(this.data.BookingDetail.ClientDefaultId != null) {
      this.data.BookingDetail.CompanyName = this.companies.find(x => x.Id == this.data.BookingDetail.ClientDefaultId).InvoiceName ? this.companies.find(x => x.Id == this.data.BookingDetail.ClientDefaultId).InvoiceName : ''
      this.data.BookingDetail.CompanyAddress = this.companies.find(x => x.Id == this.data.BookingDetail.ClientDefaultId).Address1
      this.data.BookingDetail.GuestPhoneTakeInvoice = this.companies.find(x => x.Id == this.data.BookingDetail.ClientDefaultId).Tel1
      this.data.BookingDetail.TaxCode = this.companies.find(x => x.Id == this.data.BookingDetail.ClientDefaultId).TaxCode
      this.data.BookingDetail.GuestEmailTakeInvoice = this.companies.find(x => x.Id == this.data.BookingDetail.ClientDefaultId).Email
      }
      else{
        this.data.BookingDetail.CompanyName = ''
        this.data.BookingDetail.CompanyAddress = ''
        this.data.BookingDetail.TaxCode = ''
        this.data.BookingDetail.GuestEmailTakeInvoice = ''
        this.data.BookingDetail.GuestPhoneTakeInvoice = ''
      }
     
    },
    async save() {
      const body = {
        BookingDetail: {
            Id: this.data.BookingDetail.Id,
            GuestNameTakeInvoice: this.data.BookingDetail.GuestNameTakeInvoice,
            IsGuestTakeInvoice: this.data.BookingDetail.IsGuestTakeInvoice,
            ClientId: this.data.BookingDetail.ClientId,
            CompanyName: this.data.BookingDetail.CompanyName,
            CompanyAddress: this.data.BookingDetail.CompanyAddress,
            TaxCode: this.data.BookingDetail.TaxCode,
            GuestEmailTakeInvoice: this.data.BookingDetail.GuestEmailTakeInvoice,
            GuestPhoneTakeInvoice: this.data.BookingDetail.GuestPhoneTakeInvoice,
          },
        }
        const response = await booking.updateInfoEinvoiceBookingDetail(body)
        this.showResToast(response)
        if (response.Status === '200') {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-update-booking-einvoice')
            this.$emit('event', { type: 'after-update-booking-einvoice' })
          })
       
        }
     
      },
      
    onTriggeredEventHandler(payload) {
      if (payload.evt.ctrlKey && payload.key === S) {
        this.save()
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/variables';
@import '@core/scss/vue/libs/vue-slider.scss';
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 900px;
  }
  .b-table-sticky-header {
    overflow-y: auto !important;
    max-height: 85% !important;
  }
}
.banned-background-color-primary {
  background: rgba(17, 74, 159, 0.12);
}
.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}
.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.color-primary {
  color: $primary;
}
.form-group-einvoice{
  width: 400px;
}
.span-group-einvoice{
  margin-right: 20px;
  width: 100px;
  .span-text-einvoice{
    margin-top: 9px !important;
  }
}
.disabled {
  background: rgba(193, 193, 193, 0.12)
}
</style>
