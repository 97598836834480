import dayjs from 'dayjs'
import { booking } from '@/api/booking'
import { invoice } from '@/api/invoice'
// import { commonServices } from '@/api/common-services'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import { convertUTC, formatPrice } from '@/@core/utils/utils'
import ExcelJS from "exceljs";
import modalViewEInvoice from './modalViewEInvoice.vue'
import AdjustInvoiceVAT from '@/views/modules/invoice/ListPayment/SidebarMergeService/AdjustInvoiceVAT.vue'
import ReplaceInvoiceVAT from '@/views/modules/invoice/ListPayment/SidebarMergeService/ReplaceInvoiceVAT.vue'
import issuePublicInvoiceVue from '@/views/modules/invoice/ListPayment/SidebarMergeService/issuePublicInvoice.vue'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

const dataGridRef = 'dataGrid'
export default {
  name: 'ListPayment',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    ConfirmDialog,
    modalViewEInvoice,
    AdjustInvoiceVAT,
    ReplaceInvoiceVAT,
    issuePublicInvoiceVue,
  },
  data() {
    return {
      dataGridRef,
      search: {
        InvoiceNo: '',
        KeyWord: '',
        FromDate: this.getUTC('start', 'day', -7),
        ToDate: this.getUTC('start', 'day'),
      },
      paymentDetail: [],
      IssueInvoiceIdProps: null,
      listInvoice: [],
      perPage: 50,
      allMode: 'allPages',
      checkBoxesMode: 'onClick', // 'always' :
      optionPerPage: [50, 100, 150],
      configTable: [
        {
          caption: 'SerialNo',
          field: 'SerialNo',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: 'InvoiceNo',
          field: 'InvoiceNo',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_tax_invoice_invoice_old_no'),
          field: 'InvoiceOldNo',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_common_full_name'),
          field: 'MemberName',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: 'InvoiceDate',
          field: 'InvoiceDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'InvoiceDate',
        },
        {
          caption: this.$t('golf_tax_invoice_company_name'),
          field: 'CompanyName',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_tax_invoice_company_email'),
          field: 'Email',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_tax_invoice_company_address'),
          field: 'CompanyAddress',
          isShow: true,
          alignment: 'left',
          // cellTemplate: 'PaymentDate',
        },
        {
          caption: this.$t('golf_tax_invoice_tax_code'),
          field: 'TaxCode',
          isShow: true,
          // cellTemplate: 'TotalAmount',
          alignment: 'right',
        },
        {
          caption: this.$t('golf_tax_invoice_payment_method'),
          field: 'PaymentMethodId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'PaymentMethodId',
        },
        {
          caption: this.$t('golf_common_total'),
          field: 'TotalAmount',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'TotalAmount',
        },
        {
          caption: this.$t('golf_payment_cashier'),
          field: 'CashierCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'CashierCode',
        },
        {
          caption: this.$t('golf_common_status'),
          field: 'Status',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'Status',
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
        },
      ],
      isLoading: false,
      allMode: 'allPages',
      checkBoxesMode: 'onClick',
      selectedRowsData: [],
      isLoadingExportExcel: false,
      min: null,
      max: null,
      check: null,
      checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
      dataViewDetailEInvoice: {
        urlInvocie: null,
        dataInvoice: null
      },
      listMember: [],
      typeAdjustInvoice:'',
      isLoading: false,
      listClient: [],
      listCashiers: [],
      zoneId: null,
      listPaymentType: [],
      listPaymentTypeEInvoice: [],
      listPaymentTypeOpstion: [],
      listPaymentMethodEInvocie: [],
      ListFormNo:[],
      ListSerialNo:[],
      ListSerialNoAll:[],
      fullNameDefaultVisitorNoInvoice:'',
      dataPropsDetailInvoice: null,
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  created() {
    this.getListInvoice_RIVAT01()
    this.getConfigInfo_RCOM01()
    this.getListCashier_RCS01() 
  },
  methods: {
    async deleteInvoice(data) {
      console.log(data)
      await invoice.api_DIVAT02({ Invoice: { Id: data.Id } }).then(async response => {
        if (response.Status === '200') {
          this.showResToast(response)
          this.getListInvoice_RIVAT01()
        }
      })
    },
    getSelectedData() {
      this.selectedRowsData = this.dataGrid.getSelectedRowsData()
    },
    _dayjs: dayjs,
    async getConfigInfo_RCOM01() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
          return this.getListPaymentType_RPF02()
        }
      })
    },
    
    async getConfigInfo_RCOM01() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_ACCOUNTANT')
          return this.getListPaymentType_RPF02()
        }
      })
    },
    async getConfigInfo_buyer_notName_RCOM01() {
      // const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      // if (response.Status === '200') {
      //   this.SerialNoDefault = response.Data.find(x => x.KeyCode === 'PAYMENT_INVOICE_VAT_SERIALNO').KeyValue
      //   this.FormNoDefault = response.Data.find(x => x.KeyCode === 'PAYMENT_INVOICE_VAT_FORMNO').KeyValue
      // }
      const responseModule = await booking.getConfigInfo({ KeyGroup: 'MODULE' })
      if (responseModule.Status === '200') {
        this.fullNameDefaultVisitorNoInvoice =  responseModule.Data.find(x => x.KeyCode === 'EINVOICE_BUYER_NAME_WHEN_NOT_GET_INVOICE').KeyValue
      }
    },
    async getListPaymentType_RPF02() {
      await booking.api_RPF02({
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          const tmp = response?.Data?.PaymentType
          this.listPaymentTypeEInvoice = response?.Data?.PaymentType
          tmp.forEach(x => {
            x.PaymentMethod.forEach(y => {
              if (this.listPaymentType.filter(z => z.Id == y.Id).length == 0) {
                this.listPaymentType.push({
                  Id: y.Id,
                  Name: y.Name,
                })
              }
            })
          })
          this.listPaymentTypeOpstion = response?.Data?.PaymentType.map(x => ({
            title: x.Name,
            value: x.Code,
          }))
        }
      })
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listCashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    async getListClient() {
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listClient = res.Data.Client
          }
        })
    },
    async getListPaymentMethodEInvoice() {
      await invoice
        .api_RPMI04()
        .then(res => {
          if (res.Status === '200') {
            this.listPaymentMethodEInvocie = res.Data.map(x => ({
              title: x.Name,
              value: x.Code,
            }))
          }
        })
    },
    async call_api_RICF01() {
      // Lấy danh sách config
      const response= await invoice.api_RICF01()
          if (response.Status === '200') {
            this.ListFormNo =this.desired_output(response.Data.InvoiceConfig).map(e=>({
              FormNo:e
            }));
            this.ListSerialNoAll =response.Data.InvoiceConfig
            const checkDefaultInvoice = response.Data.InvoiceConfig.filter(x => x.DefaultInvoiceSymbol === true)
            if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
              this.SerialNoDefault = checkDefaultInvoice[0].SerialNo
              this.FormNoDefault = checkDefaultInvoice[0].FormNo
              this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.FormNoDefault)
            } }
    },
    desired_output(arr){
      let unique_values = arr
          .map((item) => item.FormNo)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
      return unique_values;
    },
    async getListInvoice_RIVAT01() {
      this.isLoading = true
      await invoice.api_RIVAT01(this.search)
        .then(res => {
          if (res) {
            this.listInvoice = res.Data.Invoice
          }
        })
      this.isLoading = false
    },
    async openPrintInvoice(data) {
      if (data.column.name != 'action' && data.data.Id && data.column.type != 'selection') {
        if(this.checkModuleEInvocie[0]=='2') {
          this.isLoading = true
          if(data.data.EinvoiceStatus == 'PUBLISH') {
            await this.viewEInvocie_RIVAT03(data.data.Id,"DownloadPDF")
          } else {
            await this.viewEInvocie_RIVAT03(data.data.Id,"DownloadPDFInvoiceDraft")
          }
          this.isLoading = false
        } else {
          const routeData = this.$router.resolve({ name: 'InvoiceVAT', params: { id: data.data.Id } })
          window.open(routeData.href, '_blank')
        }
      }
    },
    async openInvoice(data) {
        if(this.checkModuleEInvocie[0]=='2') {
          this.isLoading = true
          if(data.EinvoiceStatus == 'PUBLISH') {
            await this.viewEInvocie_RIVAT03(data.Id,"DownloadPDF")
          } else {
            await this.viewEInvocie_RIVAT03(data.Id,"DownloadPDFInvoiceDraft")
          }
          this.isLoading = false
        } else {
          const routeData = this.$router.resolve({ name: 'InvoiceVAT', params: { id: data.Id } })
          window.open(routeData.href, '_blank')
        }
      
    },
    openIssuePublicInvoice(data) {
      this.paymentDetail = []
      this.paymentDetail.push(data)
      this.IssueInvoiceIdProps = data
      this.$bvModal.show('modal-public-invoice-VAT')
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Invoice')
      const dataPaymentMethod = this.listPaymentType
      const dataCashier = this.listCashiers
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell(options) {
          const { gridCell, excelCell } = options
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField == 'InvoiceDate') {
              excelCell.value = convertUTC(gridCell.value, 'DD/MM/YYYY HH:mm')
            }
            if (gridCell.column.dataField == 'TotalAmount') {
              excelCell.value = formatPrice(gridCell.value)
            }
            if (gridCell.column.dataField == 'PaymentMethodId') {
              excelCell.value = dataPaymentMethod.find(x => x.Id == gridCell.value).Name
            }
            if (gridCell.column.dataField == 'CashierCode') {
              excelCell.value = dataCashier.find(x => x.id == gridCell.value).label
            }
          }
        },
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = `DANH SÁCH HÓA ĐƠN (từ ${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')} đến ${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')})`
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Invoice.xlsx')
        })
      })
    },
    async exportExcelInvoice() {
      this.getSelectedData()
        this.isLoadingExportExcel = true
        let dataExportExcel = []
        const invoiceIds = this.selectedRowsData.map(row => row.Id);
        const body = {
            FromDate: null,
            ToDate: null,
            InvoiceId: invoiceIds
        };
        await invoice.api_RPVAT01(body).then(res => {
            if(res.Status == '200') {
              dataExportExcel=res.Data;
              this.generateXLSX(dataExportExcel);
              this.isLoadingExportExcel = false
              } else {
                this.isLoadingExportExcel = false
                this.showToast('error', this.$t('golf_common_error_number_invoice'))
            }
        })
    },
    generateXLSX(data) {
      if (!data || data.length === 0) {
          console.error("No data available to export.");
          return;
      }
  
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Chi tiết Hoá đơn");
  
      // Tiêu đề chính (dòng 1)
      worksheet.mergeCells(`A1:P1`);
      const titleCell = worksheet.getCell("A1");
      titleCell.value = "CHI TIẾT HOÁ ĐƠN ĐIỆN TỬ";
      titleCell.font = { bold: true, size: 14, color: { argb: "FF0000" } }; // Màu đỏ
      titleCell.alignment = { horizontal: "center", vertical: "middle" };
  
      // Tiêu đề cột (dòng 2)
      worksheet.getRow(2).values = [
          "Ngày",
          "Mã hoá đơn VAT",
          "Công ty",
          "Diễn giải",
          "Tên Khách Hàng",
          "ĐVT",
          "Số lượng",
          "Đơn giá trước thuế, phí",
          "Tiền hàng",
          "Chiết khấu",
          "PPV 5%",
          "Thuế TTĐB",
          "Thuế VAT",
          "Tiền thuế",
          "Tổng cộng",
          "Hình thức thanh toán",
      ];
  
      // Căn giữa tiêu đề cột
      worksheet.getRow(2).eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { horizontal: "center", vertical: "middle" };
      });
  
      // Định nghĩa cột (bao gồm key, width, và định dạng tiền)
      worksheet.columns = [
          { key: "InvoiceDate", width: 15, style: { numFmt: "dd/mm/yyyy" } }, // Định dạng ngày
          { key: "InvoiceNo", width: 15 },
          { key: "CompanyName", width: 15 },
          { key: "ItemName", width: 25 },
          { key: "MemberName", width: 20 },
          { key: "UnitName", width: 5 },
          { key: "Quantity", width: 10 },
          { key: "Price", width: 20, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "BeforeDiscountAmount", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "DiscountAmount", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "ServiceCharge", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "SpecialTaxAmount", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "TaxRate", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "TaxAmount", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "AfterTaxAmount", width: 15, style: { numFmt: "#,##0.00" } }, // Định dạng tiền
          { key: "PaymentMethod", width: 20 },
      ];
  
      // Cố định tiêu đề (dòng 1 và dòng 2)
      worksheet.views = [{ state: "frozen", xSplit: 0, ySplit: 2 }];
  
      // Ghi dữ liệu (sử dụng key để ánh xạ)
      data.forEach((item) => {
          // Chuyển đổi ngày từ chuỗi ISO sang đối tượng Date
          if (item.InvoiceDate) {
              item.InvoiceDate = new Date(item.InvoiceDate);
          }
          worksheet.addRow(item);
      });
  
      // Hàng tổng (sau dữ liệu)
      const totalRowIndex = data.length + 3;
      const totalRow = worksheet.getRow(totalRowIndex);
  
      // Gộp ô cho nhãn "TỔNG"
      worksheet.mergeCells(`A${totalRowIndex}:G${totalRowIndex}`);
      const totalLabelCell = worksheet.getCell(`A${totalRowIndex}`);
      totalLabelCell.value = "TỔNG";
      totalLabelCell.font = { bold: true, size: 10 };
      totalLabelCell.alignment = { horizontal: "center", vertical: "middle" };
  
      // Công thức tính tổng
      totalRow.getCell("H").value = { formula: `SUM(H3:H${data.length + 2})` };
      totalRow.getCell("I").value = { formula: `SUM(I3:I${data.length + 2})` };
      totalRow.getCell("J").value = { formula: `SUM(J3:J${data.length + 2})` };
      totalRow.getCell("K").value = { formula: `SUM(K3:K${data.length + 2})` };
      totalRow.getCell("L").value = { formula: `SUM(L3:L${data.length + 2})` };
      totalRow.getCell("N").value = { formula: `SUM(N3:N${data.length + 2})` };
      totalRow.getCell("O").value = { formula: `SUM(O3:O${data.length + 2})` };
  
      // Làm đậm cả dòng tổng
      totalRow.eachCell((cell) => {
          cell.font = { bold: true };
      });
  
      // Xuất file
      workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), `ChiTietHoaDon.xlsx`);
      });
  },
    async createEinvocieDraft_IIVAT02(InvocieGolfId) {
      const body = {
        InvoiceGolfId: InvocieGolfId
      }
      const response =  await invoice.api_IIVAT02(body)
      return response
    },
    async issueEinvocie_IIVAT03(InvocieGolfId) {
      const body = {
        InvoiceGolfId: InvocieGolfId
      }
      const response =  await invoice.api_IIVAT03(body)
      return response
    },
    async viewEInvocie_RIVAT03(InvocieGolfId, ViewType) {
      const body = {
        InvoiceGolfId: InvocieGolfId,
        Type: ViewType
      }
      const response =  await invoice.api_RIVAT03(body)
      if(response.Status == 200) {
        if(response.Data.Type === 1){
          const decodedData = Buffer.from(response.Data.InvoicePDF, 'base64')
          const newBlob = new Blob([decodedData], {
            type: 'application/pdf',
          })
          const url = window.URL.createObjectURL(newBlob)
          this.dataViewDetailEInvoice.urlInvocie = url
          this.dataViewDetailEInvoice.dataInvoice = InvocieGolfId
          this.$bvModal.show('modal-view-invoice-VAT')
          this.$refs.viewEInvocie.open()
        } else {
          window.open(response.Data.InvoicePDF, '_blank', 'noreferrer');

        }
       
      }
    },
    async createEinvocieDraft() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        const response_IIVAT02 = await this.createEinvocieDraft_IIVAT02(this.selectedRowsData[0].Id)
        this.showResToast(response_IIVAT02)
        if(response_IIVAT02.Status == 200) {
          if(this.selectedRowsData[0].EinvoiceStatus == 'PUBLISH') {
            await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDF")
          } else {
            await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDFInvoiceDraft")
          }
          this.isLoading = false
        }
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    async viewEInvocie() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        if(this.selectedRowsData[0].EinvoiceStatus == 'PUBLISH') {
          await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDF")
        } else {
          await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDFInvoiceDraft")
        }
        this.isLoading = false
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    handleEvent(event) {
      if(event.type == 'refresh_list_invoice') {
        this.getListInvoice_RIVAT01()
      }
    },
    async issueEinvoice() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        if(this.selectedRowsData[0].EinvoiceStatus != "PUBLISH") {
          const response = await this.issueEinvocie_IIVAT03(this.selectedRowsData[0].Id)
          this.showResToast(response)
          if(response.Status == 200) {
            await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDF")
          }
          this.isLoading = false
        } else {
          this.showToast('error', this.$t('golf_invoice_EInvoice_issued'))
          this.isLoading = false
        }
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    async deleteEinvoice() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {

        if(this.selectedRowsData[0].EinvoiceStatus === 'PUBLISH') {
          const body = {
            InvoiceGolfId: this.selectedRowsData[0].Id
        }
        await invoice.api_DIVAT03(body).then(async response => {
          if (response.Status === '200') {
            response.Messages=[];
            this.showResToast(response)
            this.getListInvoice_RIVAT01()
          }
        })
        this.isLoading = false
        } else if (this.selectedRowsData[0].EinvoiceStatus === 'DRAFT') {

          const body = {
            Invoice: {
              Id: this.selectedRowsData[0].Id,
            },
          }
          await invoice.api_DIVAT01(body).then(res => {
            if (res.Status === '200') {
              res.Messages=[];
            this.showResToast(res)
            this.getListInvoice_RIVAT01()
            }
          }) 
          this.isLoading = false
        }
        else {
          this.showToast('error', this.$t('golf_invoice_has_an_inappropriate_status'))
          this.isLoading = false
        }
       
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    async deleteEinvoiceNav(data) {
      this.isLoading = true
        if(data.Invoice.EinvoiceStatus === 'PUBLISH') {
          const body = {
            InvoiceGolfId: data.Invoice.Id
        }
        await invoice.api_DIVAT03(body).then(async response => {
          if (response.Status === '200') {
            response.Messages=[];
            this.showResToast(response)
            this.getListInvoice_RIVAT01()
          }
        })
        this.isLoading = false
        } else if (data.Invoice.EinvoiceStatus === 'DRAFT') {

          const body = {
            Invoice: {
              Id: data.Invoice.Id,
            },
          }
          await invoice.api_DIVAT01(body).then(res => {
            if (res.Status === '200') {
              res.Messages=[];
            this.showResToast(res)
            this.getListInvoice_RIVAT01()
            }
          }) 
          this.isLoading = false
        }
        else {
          this.showToast('error', this.$t('golf_invoice_has_an_inappropriate_status'))
          this.isLoading = false
        }
       
      
    },
    async confimDeleteEivoice(){
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        this.$refs.confirmDialogComponent1.open({
          dataConfirm: {
            title: 'Huỷ hóa đơn',
            content: 'Bạn có chắc chắn Huỷ hóa đơn?',
          }
        })
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
      
    },
    viewAdjustIncreaseInvoiceVAT(data) {
      this.typeAdjustInvoice='Increase'
      this.dataPropsDetailInvoice = data
      
      this.$bvModal.show('modal-adjust-invoice-VAT')
    },
    viewAdjustDecreaseInvoiceVAT(data) {
      this.typeAdjustInvoice='Decrease'
      this.dataPropsDetailInvoice = data
      this.$bvModal.show('modal-adjust-invoice-VAT')
    },
    viewReplaceInvoiceVAT(data) {
      
      this.dataPropsDetailInvoice = data
      this.$bvModal.show('modal-replace-invoice-VAT')
    },
    
  },
}