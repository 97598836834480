<template>
    <b-modal
      id="modal-view-invoice-VAT"
      v-model="isOpen"
      size="xl"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
        <b-card-header
            class="banned-background-color"
        >
            <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0">
              {{ $t('golf_invoice_view_detail_EInvoice') }}
            </h4>
            <ez-icon
                icon="ezGolf-icon-x"
                class="cursor-pointer"
                size="24"
                @click="hideModal"
            />
            </div>
        </b-card-header>
        
        <b-card-body>
            <iframe id="viewDetailEinvocie" style="height: 100%;width: 100%;">

            </iframe>
        </b-card-body>
            
        <template #modal-footer>
            <b-button   v-if="InvoiceDetail.EinvoiceStatus == 'DRAFT'"   
                        variant="primary" 
                        @click="openIssuePublicInvoice(InvoiceDetail)"
                        :disabled="isLoading"
                    >
                {{ $t("golf_sidebar_checkout_public_order") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button   v-if="InvoiceDetail.EinvoiceStatus == 'PUBLISH' || InvoiceDetail.EinvoiceStatus == 'BE_ADJUST'"   
                        variant="primary" 
                        @click="viewAdjustIncreaseInvoiceVAT(InvoiceDetail)"
                        :disabled="isLoading"
                    >
                {{ $t("golf_sidebar_checkout_increase_invoice") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button   v-if="InvoiceDetail.EinvoiceStatus == 'PUBLISH' || InvoiceDetail.EinvoiceStatus == 'BE_ADJUST'"   
                        variant="primary" 
                        @click="viewAdjustDecreaseInvoiceVAT(InvoiceDetail)"
                        :disabled="isLoading"
                    >
                {{ $t("golf_sidebar_checkout_decrease_invoice") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button   v-if="InvoiceDetail.EinvoiceStatus == 'PUBLISH'|| InvoiceDetail.EinvoiceStatus == 'REPLACE'"   
                        variant="primary" 
                        @click="viewReplaceInvoiceVAT(InvoiceDetail)"
                        :disabled="isLoading"
                    >
                {{ $t("golf_sidebar_checkout_replace_invoice") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button v-if="InvoiceDetail.EinvoiceStatus == 'PUBLISH' || InvoiceDetail.EinvoiceStatus == 'DRAFT' "
             variant="danger" :disabled="isLoading" 
             @click="confimDeleteEivoice">
                {{ $t("golf_sidebar_checkout_delete_order") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button  variant="secondary" @click="hideModal" :disabled="isLoading"> 
                {{ $t('golf_common_back') }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
            </b-button>
        </template>
        <confirm-dialog
      ref="confirmDialogComponent3"
      @confirmDialog="deteleInvocie_DIVAT03"
    />
        <AdjustInvoiceVAT
      :data="dataPropsDetailInvoice"
      :listMember="listMember"
      :listClient="listClient"
      :listPaymentTypeOpstion="paymentTypeOptions"
      :listPaymentType="listPaymentType"
      :listCashiers="listCashiers"
      :ListFormNo="ListFormNo"
      :ListSerialNo="ListSerialNo"
      :typeAdjustInvoice = "typeAdjustInvoice"
      :listPaymentMethodEInvocie="listPaymentMethodEInvocie"
     />
     <ReplaceInvoiceVAT 
      :data="dataPropsDetailInvoice"
      :listMember="listMember"
      :listClient="listClient"
      :listPaymentTypeOpstion="paymentTypeOptions"
      :listPaymentType="listPaymentType"
      :listCashiers="listCashiers"
      :ListFormNo="ListFormNo"
      :ListSerialNo="ListSerialNo"
      :fullNameDefaultVisitorNoInvoice ="fullNameDefaultVisitorNoInvoice"
      :listPaymentMethodEInvocie="listPaymentMethodEInvocie"
     />
     <issuePublicInvoiceVue
      :dataPayment="paymentDetail"
      :IssueInvoiceIdProps="IssueInvoiceIdProps"
      @event="handleEvent"
    />
    </b-modal>
</template>
<script>
import { booking } from '@/api/booking'
import { invoice } from '@/api/invoice'
import { commonServices } from '@/api/common-services'
import AdjustInvoiceVAT from '@/views/modules/invoice/ListPayment/SidebarMergeService/AdjustInvoiceVAT.vue'
import ReplaceInvoiceVAT from '@/views/modules/invoice/ListPayment/SidebarMergeService/ReplaceInvoiceVAT.vue'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import issuePublicInvoiceVue from '@/views/modules/invoice/ListPayment/SidebarMergeService/issuePublicInvoice.vue'

export default {
    data() {
        return {
            isOpen: false,
            urliframe: null,
            InvoiceDetail: {
                Id: null,
                EinvoiceStatus: '',
                PaymentBookingDetailService: [],
            },
            listMember: [],
            typeAdjustInvoice:'',
            isLoading: false,
            listClient: [],
            listCashiers: [],
            zoneId: null,
            listPaymentType: [],
            paymentTypeOptions: [],
            listPaymentMethodEInvocie: [],
            ListFormNo:[],
            ListSerialNo:[],
            ListSerialNoAll:[],
            fullNameDefaultVisitorNoInvoice:'',
            checkModuleEInvocie: JSON.parse(localStorage.getItem('module')).find(x => x == "EINVOICE"),
            dataPropsDetailInvoice: null,
            paymentDetail: [],
            IssueInvoiceIdProps: null


        }
    },
    components: {
    AdjustInvoiceVAT,
    ReplaceInvoiceVAT,
    ConfirmDialog,
    issuePublicInvoiceVue,
  },
    props: [
        'data'
    ],
    watch: {
        isOpen(value) {
            if(!value) {
                this.$emit('event', {type: 'refresh_list_invoice'})
            }
            else
            {
                this.getConfigInfo_RCOM01()
                this.getConfigInfo_buyer_notName_RCOM01()
                this.getListCashier_RCS01()
                this.getListClient()
                this.getListPaymentMethodEInvoice()
                this.call_api_RICF01()
            }
        },
        

    },
    methods: {
        hideModal() {
            this.isOpen = false
        },
        async getConfigInfo_RCOM01() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_ACCOUNTANT')
          return this.getListPaymentType_RPF02()
        }
      })
    },
    async getConfigInfo_buyer_notName_RCOM01() {
      // const response = await booking.getConfigInfo({ KeyGroup: 'PAYMENT_CONFIG' })
      // if (response.Status === '200') {
      //   this.SerialNoDefault = response.Data.find(x => x.KeyCode === 'PAYMENT_INVOICE_VAT_SERIALNO').KeyValue
      //   this.FormNoDefault = response.Data.find(x => x.KeyCode === 'PAYMENT_INVOICE_VAT_FORMNO').KeyValue
      // }
      const responseModule = await booking.getConfigInfo({ KeyGroup: 'MODULE' })
      if (responseModule.Status === '200') {
        this.fullNameDefaultVisitorNoInvoice =  responseModule.Data.find(x => x.KeyCode === 'EINVOICE_BUYER_NAME_WHEN_NOT_GET_INVOICE').KeyValue
      }
    },
    async getListPaymentType_RPF02() {
      await booking.api_RPF02({
        
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          this.listPaymentType = response?.Data?.PaymentType
          this.paymentTypeOptions = response?.Data?.PaymentType.map(x => ({
            title: x.Name,
            value: x.Code,
          }))
        }
      })
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listCashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    async getListClient() {
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listClient = res.Data.Client
          }
        })
    },
    async getListPaymentMethodEInvoice() {
      await invoice
        .api_RPMI04()
        .then(res => {
          if (res.Status === '200') {
            this.listPaymentMethodEInvocie = res.Data.map(x => ({
              title: x.Name,
              value: x.Code,
            }))
          }
        })
    },
    async call_api_RICF01() {
      // Lấy danh sách config
      const response= await invoice.api_RICF01()
          if (response.Status === '200') {
            this.ListFormNo =this.desired_output(response.Data.InvoiceConfig).map(e=>({
              FormNo:e
            }));
            this.ListSerialNoAll =response.Data.InvoiceConfig
            const checkDefaultInvoice = response.Data.InvoiceConfig.filter(x => x.DefaultInvoiceSymbol === true)
            if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
              this.SerialNoDefault = checkDefaultInvoice[0].SerialNo
              this.FormNoDefault = checkDefaultInvoice[0].FormNo
              this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.FormNoDefault)
            } }
    },
    desired_output(arr){
      let unique_values = arr
          .map((item) => item.FormNo)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
      return unique_values;
  },
    viewAdjustIncreaseInvoiceVAT(data) {
        console.log(data)
      this.typeAdjustInvoice='Increase'
      this.dataPropsDetailInvoice = data
      console.log(this.dataPropsDetailInvoice)
      
      this.$bvModal.show('modal-adjust-invoice-VAT')
    },
    viewAdjustDecreaseInvoiceVAT(data) {
      this.typeAdjustInvoice='Decrease'
      this.dataPropsDetailInvoice = data
      this.$bvModal.show('modal-adjust-invoice-VAT')
    },
    viewReplaceInvoiceVAT(data) {
      this.dataPropsDetailInvoice = data
      this.$bvModal.show('modal-replace-invoice-VAT')
    },
    async open() {
            this.isLoading = true
            await this.detailInvocie_RIVAT02()
            setTimeout(() => {
                document.querySelector('#viewDetailEinvocie').src = this.data.urlInvocie;
                this.isLoading = false
            },500)
    },
    handleEvent() {
      this.detailInvocie_RIVAT02()
      this.viewEInvocie_RIVAT03(this.data.dataInvoice,'DownloadPDF')
    },
    async issueEinvocie_IIVAT03() {
            this.isLoading = true
            var body = {
                InvoiceGolfId: this.data.dataInvoice
            }
            await this.PublicInvoice_UIVAT02().then(async response => { 
                if(response.Status == 200) {
                    const res = await invoice.api_IIVAT03(body)
                    await this.detailInvocie_RIVAT02()
                    if(res.Status == 200) {
                        await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDF')
                    }
                    this.showResToast(res)

                } else {
                    this.showResToast(response)
                }
                this.isLoading = false
            })
            // await invoice.api_IIVAT03(body).then(async res => {
            //     if(res.Status == 200) {
            //         await this.PublicInvoice_UIVAT02().then(async response => {
            //             this.showResToast(response)
            //             await this.detailInvocie_RIVAT02()
            //             await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDF')
            //         })
                    
            //     } else {
            //         this.showResToast(res)
            //     }
            //     this.isLoading = false
            // })
    },
    async viewEInvocie_RIVAT03(InvocieGolfId, ViewType) {
            const body = {
                InvoiceGolfId: InvocieGolfId,
                Type: ViewType
            }
            const response =  await invoice.api_RIVAT03(body)
            if(response.Status == 200) {
                if(response.Data.Type === 1){
                    const decodedData = Buffer.from(response.Data.InvoicePDF, 'base64')
                    const newBlob = new Blob([decodedData], {
                    type: 'application/pdf',
                })
                const url = window.URL.createObjectURL(newBlob)
                document.querySelector('#viewDetailEinvocie').src = url
                }else {
                    window.open(response.Data.InvoicePDF, '_blank', 'noreferrer');
                }
               
            }
    },
    async detailInvocie_RIVAT02() {
            const body = {
                Invoice: {
                    Id: this.data.dataInvoice
                }
            }
            const response =  await invoice.api_RIVAT02(body)
            if(response.Status == 200) {
                this.InvoiceDetail = response.Data.Invoice
                this.InvoiceDetail.PaymentBookingDetailService =  response.Data.PaymentBookingDetailService
            }
    },
    async deteleInvocie_DIVAT03 () {
            this.isLoading = true
            if(this.InvoiceDetail.EinvoiceStatus === 'PUBLISH') {
              const body = {
                InvoiceGolfId: this.data.dataInvoice
            }
            const response =  await invoice.api_DIVAT03(body)
            if(response.Status == 200 ) {
              await this.detailInvocie_RIVAT02()
                    await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDFInvoiceDraft')
                    this.showResToast(res)
                // await invoice.api_UIVAT03({
                //     Invoice: {
                //     Id: this.data.dataInvoice
                //     }
                // }).then(async res => {
                //     await this.detailInvocie_RIVAT02()
                //     await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDFInvoiceDraft')
                //     this.showResToast(res)
                // })
            } else {
                await this.detailInvocie_RIVAT02()
                await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDFInvoiceDraft')
                this.showResToast(response)
            }
            this.isLoading = false
            }
            else if(this.InvoiceDetail.EinvoiceStatus === 'DRAFT'){
              const body = {
            Invoice: {
              Id: this.data.dataInvoice,
            },
          }
          await invoice.api_DIVAT01(body).then(res => {
            if (res.Status === '200') {
              res.Messages=[];
            this.showResToast(res)
            this.hideModal()
            }
          }) 
          this.isLoading = false
            }
            this.isLoading = false
            
    },
    async confimDeleteEivoice(){
        this.$refs.confirmDialogComponent3.open({
          dataConfirm: {
            title: 'Huỷ hóa đơn',
            content: 'Bạn có chắc chắn Huỷ hóa đơn?',
          }
        })
    },
     openIssuePublicInvoice(data) {
      
      this.paymentDetail = []
      this.paymentDetail.push(data)
      this.IssueInvoiceIdProps = data
      this.$bvModal.show('modal-public-invoice-VAT');
    },
    async PublicInvoice_UIVAT02() {
            const body = {
                Invoice: {
                    Id: this.data.dataInvoice,
                    FormNo: this.InvoiceDetail.FormNo,
                    SerialNo: this.InvoiceDetail.SerialNo,
                    InvoiceNo: null,
                    InvoiceDate: this.InvoiceDetail.InvoiceDate
                }
            }
            const response = await invoice.api_UIVAT02(body)
            return response
        }
    }
}
</script>
<style lang="scss">
    #modal-view-invoice-VAT {
        .modal-dialog {
            height: 95%;
            max-height:95%;
            min-height: 95%;
        }
        .b-overlay-wrap {
            height: 100%;
            
        }
        .modal-content {
            height:100%;
            margin: auto;
        }
       .card-body {
            height: 94%;
       }
    }
</style>