<template>

  <b-modal
    id="edit-service-booking"
    size="sm"
    centered
    cancel-variant="none"
    :ok-title="$t('golf_buy_add_service')"
    :cancel-title="$t('golf_common_back')"
    @ok="save_service"
  >
    <template #modal-header="{ close }">
      <h4 class="mb-0 text-primary">
        {{ $t('golf_buy_edit_service_info') }}
      </h4>
      <ez-icon
        icon="ezGolf-icon-x"
        class="cursor-pointer"
        size="24"
        color="#114A9F"
        @click="close()"
      />
    </template>
    <b-card-body>
      <div class="form">
        <div class="form-block">
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_service_name') }}
            </p>
            <b-form-input
              disabled
              v-model="service.ProductName"
              :placeholder="$t('golf_common_service_name')"
            />
          </b-form-group>
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_quantity') }}
              <span style="color: red;">*</span>
            </p>
            <b-form-input
              v-model="service.Quantity"
              type="number"
              @keypress="preventDecimal"
            />
          </b-form-group>
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_price') }}
              <span style="color: red;">*</span>
            </p>
            <cleave
              :disabled="service.IsOpenProduct == 'True' ? false : true"
              v-model="service.ProductPrice"
              class="form-control"
              :raw="true"
              :options="number"
              placeholder="1,000,000"
            />
          </b-form-group>
        </div>
      </div>
    </b-card-body>
  </b-modal>

</template>

<script>
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
  },
  props: [
    'items',
    'BookingDetailId',
    'service',
  ],
  data() {
    return {
      reason: '',
      error: false,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('edit-service-booking')
    },
    async save_service(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.service.ProductName.length === 0 || this.service.ProductPrice.length == 0|| this.service.Quantity.length == 0) {
        this.$toast.error('Không bỏ trống trường bắt buộc')
      }
      this.service.Quantity = Number(this.service.Quantity)
      this.$nextTick(() => {
        this.$bvModal.hide('edit-service-booking')
      })

      this.$emit('event', { type: 'add_service', data: this.service })
    },
    preventDecimal(event) {
      const char = String.fromCharCode(event.which);
      if (!/^\d$/.test(char)) {
        event.preventDefault(); // Ngăn nhập các ký tự không phải số
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables';

.color-warning {
  color: $primary;
}

.error {
  color: red;
}

.pd15 {
  padding: 15px;
}

::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 400px;
  }
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
