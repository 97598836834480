import { commonServices } from '@/api/common-services'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import { bookingDrivingRange } from '@/api/booking-driving-range'
import { booking } from '@/api/booking'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, alpha_num,
} from '@validations'

import EditService from './EditService.vue'

export default {
  name: 'AddGolfService',
  components: {
    ValidationProvider,
    ValidationObserver,
    UserAvatar,
    EditService,
  },
  props: [
    'area',
    'dataDetail',
    'detailBookingId',
  ],
  data() {
    return {
      // area: JSON.parse(localStorage.getItem('userGolfData')).Site,
      isOpen: false,
      listService: [],
      zone: [],
      hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      keyWord: null,
      productName: 'Chọn dịch vụ',
      activePosition: {
        ProductMenuId: null,
        ProductId: null,
      },
      isLoading: true,
      selectedServices: [],
      TotalAmount: 0,
      service: {},
      zoneId: null,
      allServices: [],
      discount: {
        list: [],
      },
      optionDiscountType: [
        {
          value: 'FIX',
          label: 'VND',
        },
        {
          value: 'PERCENT',
          label: '%',
        },
      ],
    }
  },
  watch: {
    value(data) {
      if (data) {
        this.getServiceNameById(data)
      }
    },
    detailBookingId(val) {
      this.selectedServices = []
      if(val) {
        this.getServiceAdded(val)
      }
    },
  },
  computed: {
    fieldsService() {
      return [
        { key: 'ServiceName', label: this.$t('golf_common_service_name') },
        { key: 'UnitPrice', label: this.$t('golf_common_price'), class: 'text-right' },
        { key: 'Quantity', label: this.$t('golf_common_quantity'), class: 'text-right' },
        { key: 'TotalAmount', label: this.$t('golf_common_total'), class: 'text-right' },
        { key: 'action', label: '', class: 'text-right' },
      ]
    },
  },
  created() {
    this.getListZone(this.area)
    this.getServiceAdded(this.detailBookingId)
    this.getListReasonDiscount_RRC01()
  },
  mounted() {
  },
  methods: {
    preventDecimal(event) {
      const char = String.fromCharCode(event.which);
      if (!/^\d$/.test(char)) {
        event.preventDefault(); // Ngăn nhập các ký tự không phải số
      }
    },
    async getListReasonDiscount_RRC01() {
      const response = await booking.getListReasonDiscount({
        HotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      })
      if (response.Status === '200') {
        this.discount.list = response?.Data?.Reason
      }
    },
    async getServiceAdded(detailBookingId) {
      // reset dịch vụ đã chọn
      this.selectedServices = []
      await bookingDrivingRange.getRBKD2C({
        BookingDrDetail: {
          Id: detailBookingId,
        },
      }).then(response => {
        if (response.Status === '200') {
          if (response?.Data?.BookingDrDetail.BookingDrDetailService.length > 0) {
            this.selectedServices = response?.Data?.BookingDrDetail.BookingDrDetailService

            // Tính tổng số tiền khi xem chi tiết
            this.TotalAmount = 0
            this.selectedServices.forEach(x => {
              this.TotalAmount += x.TotalAmount
            })
          }
        }
      })
    },
    updateBookingService() {
      this.$refs.servicesForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const data = {
            // RetailBy: 'RECEPTIONIST',
            BookingDrDetail: {
              Id: this.dataDetail.BookingDrDetail.Id,
              BookingDrDetailService: this.params_booking_service(),
            },
          }
          bookingDrivingRange.updateBookingService(data).then(res => {
            this.showResToast(res)
            if (res.Status == '200') {
              // Load lại để lấy giá sau giảm
              this.getServiceAdded(this.detailBookingId)

              this.$emit('event', { type: 'open-sidebar-checkout' })
            }
          })
          this.isLoading = false
        }
      })
    },
    params_booking_service() {
      const data = []
      this.selectedServices.forEach((item, index) => {
        const obj = {
          Id: item.Id,
          ServiceId: item.ServiceId,
          UnitPrice: parseInt(item.UnitPrice),
          Quantity: parseInt(item.Quantity),
          Amount: item.TotalAmount,
          TotalAmount: item.TotalAmount,
          RateId: null,
          Discount: item.Discount,
          ServiceType: item.ServiceType,
          ZoneId: parseInt(item.ZoneId),
        }
        data.push(obj)
      })
      return data
    },
    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'booking-driving-range-add-service-item')
    },
    removeItem(ServiceId) {
      this.selectedServices = this.selectedServices.filter(item => item.ServiceId !== ServiceId)
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    handleSearch() {
      // const search = new RegExp(this.keyWord, 'i')
      const tmpAllService = []
      this.allServices.forEach(x => {
        const menuDetail = []
        x.MenuDetail.forEach(y => {
          if (y.ProductNameUpperCase.includes(this.keyWord.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
        })

        if (menuDetail.length) {
          tmpAllService.push({
            ProductMenuId: x.ProductMenuId,
            MenuCode: x.MenuCode,
            MenuName: x.MenuName,
            IsChildOfBar: x.IsChildOfBar,
            ParentMenuId: x.ParentMenuId,
            IsActive: x.IsActive,
            HotelId: x.HotelId,
            MenuDetail: menuDetail,
          })
        }
      })

      this.listService = tmpAllService
    },
    handleChangeQuantity(evt) {
      if (evt.target.value.trim().length === 0 || parseInt(evt.target.value) < 0) return
      this.selectedServices = this.selectedServices.map(item => {
        // Nếu tồn tại lý do giảm giá và giá trị giảm giá
        if (item.Discount[0].ReasonCode && item.Discount[0].Amount && item.Discount[0].DiscountType) {
          let tmp = 0
          if (item.Discount[0].DiscountType == 'PERCENT') {
            tmp = ((item.UnitPrice * parseInt(item.Quantity)) * parseInt(item.Discount[0].Amount)) / 100
          } else {
            tmp = parseInt(item.Discount[0].Amount)
          }
          item.TotalAmount = (item.UnitPrice * parseInt(item.Quantity)) - tmp
        } else {
          // nếu không có giảm giá
          item.TotalAmount = item.UnitPrice * parseInt(item.Quantity)
        }

        return item
      })
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    handleChangeDiscountAmountAndType() {
      this.selectedServices = this.selectedServices.map(item => {
        // Nếu tồn tại lý do giảm giá và giá trị giảm giá
        if (item.Discount[0].ReasonCode && item.Discount[0].Amount && item.Discount[0].DiscountType) {
          let tmp = 0
          if (item.Discount[0].DiscountType == 'PERCENT') {
            tmp = ((item.UnitPrice * parseInt(item.Quantity)) * parseInt(item.Discount[0].Amount)) / 100
          } else {
            tmp = parseInt(item.Discount[0].Amount)
          }
          item.TotalAmount = (item.UnitPrice * parseInt(item.Quantity)) - tmp
        } else {
          // nếu không có giảm giá
          item.TotalAmount = item.UnitPrice * parseInt(item.Quantity)
          item.Discount[0].DiscountAmount=0
        }

        return item
      })
      this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
    },
    async getListZone(area) {
      const bodyArea = {
        KeyGroup: area,
      }
      await commonServices
        .getCommon(bodyArea)
        .then(res => {
          if (res.Status === '200') {
            res.Data.forEach(x => {
              if (x.KeyCode == 'BAR_LOCATION_4_MAIN_DRVING_RANGE') {
                this.zone.push(x.KeyValue)
              }
            })
            this.getListService()
          }
        })
    },
    async getListService() {
      this.isLoading = true
      const body = {
        HotelId: this.hotelId,
        ZoneId: this.zone,
      }
      await commonServices
        .getListService(body)
        .then(res => {
          if (res.Status === '200') {
            this.listService = res.Data.Menu
            this.allServices = res.Data.Menu
            if (res.Data.Menu) {
              this.allServices.forEach(x => {
                x.MenuDetail.forEach(y => {
                  y.ProductNameUpperCase = y.ProductName.split(' ').join('').toUpperCase()
                })
              })
            }
            // Hiển thị tên service đã được chọn
            this.getServiceNameById(this.value)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    selectSevice(selectSevice, zoneId) {
      // this.isOpen = false
      this.service = selectSevice
      this.service.Quantity = 1
      this.zoneId = zoneId
      this.$bvModal.show('edit-service-booking')
    },
    handleEvent(result) {
      const selectSevice = result.data
      this.value = selectSevice.ProductId
      // const checkExits = this.selectedServices.find(item => item.ServiceId === selectSevice.ProductId)
      // if (checkExits) return

      // const temp = {}
      // temp.Quantity = 1
      // temp.TotalAmount = parseInt(selectSevice.ProductPrice)
      // temp.ServiceName = selectSevice.ProductName
      // temp.ServiceId = selectSevice.ProductId
      // temp.UnitPrice = selectSevice.ProductPrice
      // temp.ServiceType = selectSevice.ProductType
      // temp.ZoneId = this.zoneId
      // temp.Discount = [
      //   {
      //     // DiscountCode: null,
      //     ReasonCode: null,
      //     Amount: 0,
      //     DiscountType: 'PERCENT',
      //     Reasons: null,
      //   },
      // ]

      // this.selectedServices = [...[temp], ...this.selectedServices]
      // this.TotalAmount = this.selectedServices.reduce((partialSum, item) => partialSum + item.TotalAmount, 0)
      const isExitsServiceSelected = this.selectedServices.some(item => item.ServiceId === selectSevice.ProductId)
      if (!isExitsServiceSelected) {
        const temp = {
          ServiceId: selectSevice.ProductId,
          ServiceName: selectSevice.ProductName || '',
          Quantity: selectSevice.Quantity || 1,
          UnitPrice: selectSevice.ProductPrice || 0,
          TotalAmount: parseInt(selectSevice.ProductPrice * selectSevice.Quantity || 0),
          ServiceType: selectSevice.ProductType,
          ZoneId : this.zoneId,
          Discount : [
              {
                DiscountCode: null,
                ReasonCode: null,
                Amount: 0,
                DiscountType: 'PERCENT',
                Reasons: null,
              },
            ]
        }
        this.selectedServices = [...this.selectedServices, ...[temp]]
      } else {
        this.selectedServices.forEach(x => {
          if (x.ServiceId === selectSevice.ProductId) {
            x.Quantity =parseInt(x.Quantity) + parseInt(selectSevice.Quantity);
            x.TotalAmount = parseInt(x.UnitPrice * x.Quantity)
          }
        })
      }
    },
    async getServiceNameById(serviceId) {
      await this.listService.find(x => {
        x.MenuDetail.find(y => {
          if (y.ProductId === serviceId) {
            this.productName = y.ProductName
            this.activePosition.ProductMenuId = x.ProductMenuId
            this.activePosition.ProductId = y.ProductId
          }
        })
      })
    },
  },
}
