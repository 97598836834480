<template>

  <b-modal
    id="edit-service-booking"
    size="sm"
    centered
    v-model="isOpen"
  >
    <template #modal-header>
      <h4 class="mb-0 text-primary">
        {{ $t('golf_buy_edit_service_info') }}
      </h4>
      <ez-icon
        icon="ezGolf-icon-x"
        class="cursor-pointer"
        size="24"
        color="#114A9F"
        @click="hideModal()"
      />
    </template>
    <b-card-body>
      <div class="form">
        <div class="form-block">
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_service_name') }}
            </p>
            <b-form-input
              disabled
              v-model="service.ProductName"
              :placeholder="$t('golf_common_service_name')"
            />
          </b-form-group>
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_price') }}
              <span style="color: red;">*</span>
            </p>
            <cleave
              :disabled="service.IsOpenProduct == 'True' ? false : true"
              v-model="service.ProductPrice"
              class="form-control"
              :raw="true"
              :options="number"
              placeholder="1,000,000"
            />
          </b-form-group>
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_quantity') }}
              <span style="color: red;">*</span>
            </p>
            <b-form-input
              v-if="!isLoadding"
              v-model="service.Quantity"
              type="number"
            />
          </b-form-group>
          <b-form-group>
            <p class="mb-0">
              {{ $t('golf_common_type') }}
            </p>
            <v-select
              v-model="typeSelect"
              :options="listType"
              :clearable="false"
            />

          </b-form-group>
          <div v-if="typeSelect === 'Phí thường niên'">
            <div>
              <b-form-group>
                <p class="mb-0">
                  {{ $t('golf_common_contract') }}
                  <span style="color: red;">*</span>
                </p>
                <v-select
                  v-model="data.MemberSubscriptionId"
                  :options="listSubscription"
                  label="ContractCode"
                  :reduce="x => x.Id"
                  :clearable="true"
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <p class="mb-0">
                  Thời gian áp dụng
                </p>
                <div>
                  <date-range-picker
                    ref="picker"
                    v-model="data.OpenDate"
                    style="width:49%;margin-right:5px"
                    :single-date-picker="true"
                    :ranges="false"
                    opens="right"
                    :time-picker="false"
                    :time-picker-increment="1"
                    :show-dropdowns="true"
                    :auto-apply="true"
                    :locale-data="{
                      format: 'mm/dd/YYYY',
                      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                      firstDay: 0
                    }"
                  />
                  <date-range-picker
                    ref="picker"
                    v-model="data.EndDate"
                    style="width:49%"
                    :single-date-picker="true"
                    :ranges="false"
                    opens="right"
                    :time-picker="false"
                    :time-picker-increment="1"
                    :show-dropdowns="true"
                    :auto-apply="true"
                    :locale-data="{
                      format: 'mm/dd/YYYY',
                      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                      firstDay: 0
                    }"
                  />
                </div>
              </b-form-group>
            </div>
          </div>
          <ShortKey
            :shortcuts="ShortCutKey"
            :is-active="isOpen"
            :all-child-component-closed="allChildComponentClosed"
            @triggered="onTriggeredEventHandler"
          />
        </div>
      </div>
    </b-card-body>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="none"
          size="md"
          class="float-right"
          @click="hideModal()"
        >
        {{ $t('golf_common_back') }}
        </b-button>
        <b-button
          ref="saveService"
          variant="primary"
          size="md"
          class="float-right mr-1"
          @click="save_service()"
        >
          Xác nhận
        </b-button>
      </div>
      </template>
  </b-modal>

</template>

<script>
import Cleave from 'vue-cleave-component'
import { members } from '@/api/members'
import dayjs from 'dayjs'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {
  UP,DOWN
} from '@/api/constant.js'
export default {
  components: {
    Cleave,
    ShortKey
  },
  props: [
    'items',
    'BookingDetailId',
    'service',
  ],
  data() {
    return {
      ShortCutKey: [UP,DOWN],
      allChildComponentClosed: [
      ],
      reason: '',
      error: false,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      typeSelect: 'Dịch vụ',
      listType: ['Dịch Vụ', 'Package', 'Phí thường niên'],
      listSubscription: [],
      MemberSubscriptionId: null,
      data: {
        MemberSubscriptionId: null,
        OpenDate: null,
        EndDate: null,
      },
      timeZoneOffSet: localStorage.getItem('timeZoneOffset'),
      isOpen: false,
      isLoadding: false
    }
  },
  watch: {
    items() {
      this.getSubscriptions_RSU03()
    },
    isOpen(value) {
      this.data.OpenDate =  this.getFirstDayOfYear()
      this.data.EndDate =  this.getLastDayOfYear()
      if(value) {
        this.getSubscriptions_RSU03()
        if(this.service.IsAnnualFee) {
          this.typeSelect = 'Phí thường niên'
        } else {
          this.typeSelect = 'Dịch vụ'
        }
        setTimeout(() => {
          this.$refs.saveService.focus()
        },100)
      } else {
        this.listSubscription = []
        this.data.MemberSubscriptionId = null
      }
    }
  },
  methods: {
    dayjs,
    hideModal() {
      this.$bvModal.hide('edit-service-booking')
    },
    async save_service(bvModalEvent) {
      // console.log(dayjs(new Date(this.data.OpenDate).setDate(new Date(this.data.OpenDate).getDate() + 1)).format("YYYY-MM-DDTHH:mm:ss"))
      if (this.service.ProductName.length === 0 || this.service.ProductPrice.length == 0 || this.service.Quantity.length == 0) {
        this.$toast.error('Không bỏ trống trường bắt buộc')
        return
      }
      if (this.typeSelect === 'Phí thường niên') {
        if (this.data.MemberSubscriptionId.length === 0 || this.data.OpenDate.length === 0 || this.data.EndDate.length === 0) {
          this.$toast.error('Mã hợp đồng hoặc thời gian không thể bỏ trống')
          return
        }
      }
      this.service.Quantity = Number(this.service.Quantity)
      this.$nextTick(() => {
        this.$bvModal.hide('edit-service-booking')
      })
      if (this.typeSelect !== 'Phí thường niên') {
        this.$emit('event', { type: 'add_service', data: this.service })
      } else {
        this.service.MemberSubscriptionId = this.data.MemberSubscriptionId
        this.service.MemberSubscriptionFromDate = this.data.OpenDate
        this.service.MemberSubscriptionToDate = dayjs(this.data.EndDate).add(1, 'day').add(-this.timeZoneOffSet, 'minute').format('YYYY-MM-DDTHH:mm:ss')
        this.$emit('event', { type: 'add_subscription', data: this.service })
      }
    },
    async getSubscriptions_RSU03() {
      if (this.items.Id) {
        const requestData = {
          Member: { Id: this.items.Id },
        }
        await members.getSubscriptions(requestData).then(res => {
          this.listSubscription = res.Data.MemberSubscription
        })
      }
    },
    changeQualityProduct(x) {
      this.isLoadding = true
      var check = this.service.Quantity + x
      if(check > 0) {
        this.service.Quantity = check
      } else {
        this.showToast('error', 'Số lượng dịch vụ không thể nhỏ hơn 1')
      }
      this.isLoadding = false
      this.$refs.saveService.focus()
    },
    onTriggeredEventHandler(payload) {
      if (payload.key === UP) {
        this.changeQualityProduct(1)
      }
      if (payload.key === DOWN) {
        this.changeQualityProduct(-1)
      }
    },
    getFirstDayOfYear() {
      const year = this.dayjs().year()
      const timeZone = localStorage.getItem('timeZone')
      const offset = localStorage.getItem('timeZoneOffset')
      const date = this.dayjs(year + '-1-1').utc().tz(timeZone).startOf('day')
        .add(-(offset / 60), 'hour')
        .format('YYYY-MM-DDTHH:mm:ss')
      return `${date}Z`
    },
    getLastDayOfYear() {
      const year = this.dayjs().year() + 1
      const timeZone = localStorage.getItem('timeZone')
      const offset = localStorage.getItem('timeZoneOffset')
      const date = this.dayjs(year + '-1-1').utc().tz(timeZone).endOf('day')
        .add(-(offset / 60), 'hour')
        .add(-1, 'day')
        .format('YYYY-MM-DDTHH:mm:ss')
      return `${date}Z`
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables';

.color-warning {
  color: $primary;
}

.error {
  color: red;
}

.pd15 {
  padding: 15px;
}

::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 400px;
  }
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
